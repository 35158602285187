import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {QuillModule} from 'ngx-quill';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {OphBackButtonComponent} from 'src/app/shared/design/oph-back-button/oph-back-button.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {DoesTaskHaveTriggerPipe} from 'src/app/shared/pipes/does-task-have-trigger.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';
import {CurrentSkedTaskDetailRoutingModule} from './current-sked-task-detail-routing.module';
import {CurrentSkedTaskDetailComponent} from './current-sked-task-detail.component';
import {CurrentSkedTaskDetailDubaiThemeModule} from './dubai-theme/current-sked-task-detail-dubai-theme.module';
import {CurrentSkedTaskDetailMetricUpdateListComponent} from './metric-update-list/current-sked-task-detail-metric-update-list.component';
import {CurrentSkedTaskDetailStatusButtonComponent} from './status-button/current-sked-task-detail-status-button.component';
import {CurrentSkedTaskDetailSubtaskComponent} from './subtask/current-sked-task-detail-subtask.component';
import {OphInputOrangeComponent} from '../../shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HasUpdateableMetricsPipe} from './shared/has-updateable-metrics.pipe';

@NgModule({
  declarations: [CurrentSkedTaskDetailComponent],
  imports: [
    CommonModule,
    CurrentSkedTaskDetailRoutingModule,
    CurrentSkedTaskDetailDubaiThemeModule,
    OphLoadingModule,
    MatIconModule,
    CurrentSkedTaskDetailStatusButtonComponent,
    PipesModule,
    OphIconModule,
    MatExpansionModule,
    CurrentSkedTaskDetailSubtaskComponent,
    SharedCurrentSkedModule,
    OphBackButtonComponent,
    QuillModule.forRoot(),
    OphButtonModule,
    RewardCoinIconPipe,
    CurrentSkedTaskDetailMetricUpdateListComponent,
    DoesTaskHaveTriggerPipe,
    OphInputOrangeComponent,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HasUpdateableMetricsPipe,
  ],
  exports: [CurrentSkedTaskDetailComponent],
})
export class CurrentSkedTaskDetailModule {}
