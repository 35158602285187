import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, map, Observable, take} from 'rxjs';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {selectTrackers} from 'src/app/core/store/trackers/trackers.selector';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {
  OphAutocompleteComponent,
  OphAutocompleteOption,
} from 'src/app/shared/design/oph-autocomplete/oph-autocomplete.component';
import {OphCheckboxComponent} from 'src/app/shared/design/oph-checkbox/oph-checkbox.component';

@Component({
  selector: 'subtask-edit-metric-selection',
  standalone: true,
  imports: [
    OphAutocompleteComponent,
    AsyncPipe,
    MatMenuModule,
    MatTooltipModule,
    SharedCurrentSkedModule,
    OphCheckboxComponent,
  ],
  templateUrl: './subtask-edit-metric-selection.component.html',
  styleUrl: './subtask-edit-metric-selection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtaskEditMetricSelectionComponent implements OnInit {
  @Input() subtaskFormControl: FormControl;
  @Input() disabled: boolean;

  @Output() back = new EventEmitter<{metricId: string; readOnly: boolean}[]>();
  @Output() next = new EventEmitter<{metricId: string; readOnly: boolean}[]>();

  trackers$: Observable<Tracker[]>;
  autocompleteOptions$: Observable<OphAutocompleteOption[]>;

  selectedTracker$ = new BehaviorSubject<Tracker>(null);
  filteredMetrics$ = new BehaviorSubject<TrackerMetric[]>(null);

  metricsCheckedMap: {[key: string]: boolean} = {};
  metricsReadOnlyMap: {[key: string]: boolean} = {};
  initialMetricsState: {metricId: string; readOnly: boolean}[];

  constructor(private store$: Store) {}

  ngOnInit() {
    this.trackers$ = this.store$.pipe(select(selectTrackers));
    this.autocompleteOptions$ = this.trackers$.pipe(
      map(trackers =>
        trackers.map(tracker => ({
          name: tracker.name,
          _id: tracker._id,
        }))
      )
    );

    this.initialMetricsState = this.subtaskFormControl.value.metricIds.map(m => ({...m}));
    this.metricsCheckedMap = {};
    this.metricsReadOnlyMap = {};
    this.subtaskFormControl.value.metricIds.forEach((m: {metricId: string; readOnly: boolean}) => {
      this.metricsCheckedMap[m.metricId] = true;
      this.metricsReadOnlyMap[m.metricId] = m.readOnly || false;
    });
    this.checkSubtask();
  }

  checkSubtask() {
    if (this.subtaskFormControl.value.trackerId) {
      this.setSelectedSubtask();
    }
  }

  setSelectedSubtask() {
    this.trackers$.pipe(take(1)).subscribe(trackers => {
      const tracker = trackers.find(t => t._id === this.subtaskFormControl.value.trackerId);
      if (!tracker) {
        return;
      }
      this.selectedTracker$.next(tracker);
      this.setFilteredMetrics(tracker);
    });
  }

  setFilteredMetrics(tracker: Tracker) {
    this.filteredMetrics$.next(tracker.metrics?.filter(m => m.updateable) || []);
  }

  onTracker(option: OphAutocompleteOption) {
    this.subtaskFormControl.patchValue({
      trackerId: option._id,
    });
    this.trackers$.pipe(take(1)).subscribe(trackers => {
      const tracker = trackers[option.index];
      this.selectedTracker$.next(trackers[option.index]);
      this.setFilteredMetrics(tracker);
      // Initialize both maps for new tracker
      this.metricsCheckedMap = {};
      this.metricsReadOnlyMap = {};
      tracker.metrics.forEach(metric => {
        this.metricsCheckedMap[metric._id] = true;
        this.metricsReadOnlyMap[metric._id] = false; // Default to false
      });
    });
  }

  onMetricCheckChange(metricId: string, checked: boolean) {
    this.metricsCheckedMap[metricId] = checked;
  }

  onMetricReadOnlyChange(metricId: string, readOnly: boolean) {
    this.metricsReadOnlyMap[metricId] = readOnly;
  }

  onBack() {
    this.back.emit(this.initialMetricsState);
  }

  onNext() {
    // Update to include both checked state and read-only state
    const selectedMetrics = Object.entries(this.metricsCheckedMap)
      .filter(([metricId]) => this.metricsCheckedMap[metricId])
      .map(([metricId]) => ({
        metricId,
        readOnly: this.metricsReadOnlyMap[metricId] || false,
      }));
    this.next.emit(selectedMetrics);
  }
}
