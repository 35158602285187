import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';

@Component({
  selector: 'tracker-detail-project-warning-dialog',
  standalone: true,
  imports: [OphButtonModule, OphXButtonComponent],
  templateUrl: './tracker-detail-project-warning-dialog.component.html',
  styleUrl: './tracker-detail-project-warning-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailProjectWarningDialogComponent {
  constructor(private dialog: MatDialogRef<TrackerDetailProjectWarningDialogComponent, boolean>) {}

  onClose(confirm?: boolean) {
    this.dialog.close(confirm);
  }
}
