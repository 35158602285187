import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, filter, Observable, startWith, Subscription, take} from 'rxjs';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {selectTrackerAutocompleteOptions, selectTrackers} from 'src/app/core/store/trackers/trackers.selector';
import {
  OphAutocompleteComponent,
  OphAutocompleteOption,
} from '../../design/oph-autocomplete/oph-autocomplete.component';
import {OphXButtonComponent} from '../../design/oph-x-button/oph-x-button.component';
import {PipesModule} from '../../pipes/pipes.module';
import {TasksService} from '../../services/tasks.service';
import {TrackerMetricTypes} from './../../../trackers/shared/tracker-metric-types';
import {BOOLEAN_OPTIONS, OPERATOR_OPTIONS} from 'src/app/projects/shared/projects-constants';
import {OperatorOption} from 'src/app/core/model/operator-option';

@Component({
  selector: 'task-trigger-selection',
  standalone: true,
  imports: [OphAutocompleteComponent, AsyncPipe, MatMenuModule, OphXButtonComponent, PipesModule],
  templateUrl: './task-trigger-selection.component.html',
  styleUrl: './task-trigger-selection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTriggerSelectionComponent implements OnInit, OnDestroy {
  @Input() triggerFormGroup!: FormGroup;
  @Input() hideXButton: boolean;
  @Input() ophAutocompleteStyle: Object;
  @Input() ophAutocompleteNewButtonLoading: boolean;
  @Input() error: boolean;
  @Input() enableNewTrackerButton: boolean;

  @Output() removeItem = new EventEmitter();
  @Output() ophAutocompleteNewClick = new EventEmitter();

  trackers$: Observable<Tracker[]>;
  autocompleteOptions$: Observable<OphAutocompleteOption[]>;

  selectedTracker$ = new BehaviorSubject<Tracker>(null);
  selectedFilteredTrackerMetrics$ = new BehaviorSubject<TrackerMetric[]>(null);
  selectedMetric$ = new BehaviorSubject<TrackerMetric>(null);
  triggerComplete$ = new BehaviorSubject<boolean>(false);

  TrackerMetricTypes = TrackerMetricTypes;
  operatorOptions: OperatorOption[] = OPERATOR_OPTIONS;
  booleanOptions = BOOLEAN_OPTIONS;

  formSubscription: Subscription;

  constructor(
    private store$: Store,
    private tasksService: TasksService
  ) {}

  ngOnInit() {
    this.trackers$ = this.store$.pipe(select(selectTrackers));
    this.autocompleteOptions$ = this.store$.pipe(select(selectTrackerAutocompleteOptions));
    this.checkTrigger();
    this.formSubscription = this.subscribeToFormChanges();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  subscribeToFormChanges(): Subscription {
    return this.triggerFormGroup.valueChanges.pipe(startWith(this.triggerFormGroup.value)).subscribe(trigger => {
      this.triggerComplete$.next(this.tasksService.checkIfTriggerIsComplete(trigger));
    });
  }

  checkTrigger() {
    if (this.triggerFormGroup.value.trackerId) {
      this.setSelectedTracker();
    }
  }

  setSelectedTracker() {
    this.trackers$
      .pipe(
        filter(t => !!t),
        take(1)
      )
      .subscribe(trackers => {
        const tracker = trackers.find(t => t._id === this.triggerFormGroup.value.trackerId);
        if (!tracker) {
          return;
        }
        this.selectedTracker$.next(tracker);
        this.setTrackerMetrics(tracker);
        this.setSelectedMetric(tracker);
      });
  }

  setTrackerMetrics(tracker: Tracker) {
    const metrics = tracker.metrics.filter(m => m.updateable);
    this.selectedFilteredTrackerMetrics$.next(metrics);
  }

  setSelectedMetric(tracker: Tracker) {
    const metric = tracker.metrics.find(m => m._id === this.triggerFormGroup.value.metricId);
    if (!metric) {
      return;
    }
    this.selectedMetric$.next(metric);
  }

  onTracker(option: OphAutocompleteOption) {
    this.triggerFormGroup.patchValue({
      trackerId: option._id,
    });
    this.trackers$.pipe(take(1)).subscribe(trackers => {
      const tracker = trackers[option.index];
      this.selectedTracker$.next(tracker);
      this.setTrackerMetrics(tracker);
    });
  }

  onMetric(metric: TrackerMetric) {
    this.triggerFormGroup.patchValue({
      metricId: metric._id,
      value: metric.value || metric.defaultValue || '',
    });
    this.selectedMetric$.next(metric);
    if (metric.type !== TrackerMetricTypes.Numeric) {
      this.triggerFormGroup.patchValue({
        operator: 'eq',
      });
    }
  }

  onOperator(operator: OperatorOption) {
    this.triggerFormGroup.patchValue({
      operator: operator.value,
    });
  }

  onValue(value: string) {
    this.triggerFormGroup.patchValue({
      value: value,
    });
  }

  onRemoveItem() {
    this.selectedMetric$.next(null);
    this.removeItem.emit();
  }
}
