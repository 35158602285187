@if (view === 'trigger') {
  <div class="button-container">
    <task-wizard-dialog-check-button
      width="100%"
      flexGrow="true"
      [selected]="!triggersFormArray?.length"
      (click)="onNoTrigger()"
      >No trigger</task-wizard-dialog-check-button
    >
    <task-wizard-dialog-check-button
      width="100%"
      flexGrow="true"
      [selected]="triggersFormArray?.length"
      (click)="onTrigger()"
      >Tracker value</task-wizard-dialog-check-button
    >
  </div>

  @if (!triggersFormArray?.length) {
    <div class="info-container">
      <div class="left-container">
        <div class="icon-container">
          <img src="assets/img/icons/lightning-orange.svg" />
        </div>
      </div>
      <div class="right-container">
        <p>
          Triggers control when a task becomes active. For instance, you may have a task that only shows up after
          another related task has been completed, or the value of a tracked object reaches a specified condition.
        </p>
      </div>
    </div>
  }
  @if (triggersFormArray?.length) {
    @for (triggerFormGroup of triggersFormArray.controls; track control) {
      <div class="w-100">
        <task-trigger-selection
          class="mb-10"
          [triggerFormGroup]="triggerFormGroup"
          (removeItem)="onClearTrigger()"
          (ophAutocompleteNewClick)="onNewTracker()"
        ></task-trigger-selection>
      </div>
    }
    @if (scheduleTypeControl.value === 'doNotRepeat' && !eventControl.value) {
      <mat-checkbox color="primary" [formControl]="autoResetControl">Automatically reset this task</mat-checkbox>
    }
  }
}

@if (view === 'newTracker') {
  <new-tracker-in-dialog
    [projectId]="project._id"
    [hideMetricCheckboxes]="true"
    (closeNewTrackerInDialog)="onCloseNewTrackerInDialog($event?.trackerId)"
  ></new-tracker-in-dialog>
}
