@if ({valueChosen: valueChosen$ | async}; as state) {
  @if (metric?.type === trackerMetricTypes.Numeric) {
    <div class="numeric-container">
      <oph-input-orange
        [control]="control"
        [inputStyle]="inputStyle"
        [showUpdateButton]="showNumericUpdateButton"
        type="number"
        [buttonStyle]="{height: '28px'}"
        [updateButtonStatus]="loadingStatus"
        (update)="onUpdateValue($event)"
        (inputFocus)="onInputFocus()"
        (inputBlur)="onInputBlur()"
      ></oph-input-orange>
      @if (suffix) {
        <div class="suffix" [class.suffix-focused]="inputFocused">
          <span>{{ suffix }}</span>
        </div>
      }
    </div>
  }

  @if (metric?.type === trackerMetricTypes.Boolean) {
    <!-- This means it is used in current sked -->
    @if (subtaskId) {
      <div class="boolean-container">
        <div class="button-group-container">
          @for (option of booleanButtonGroupOptions; track option; let i = $index) {
            <button
              (click)="onUpdateValue(option.value)"
              [class.selected]="state.valueChosen && control?.value === option.value"
              [ngStyle]="booleanButtonStyle"
            >
              {{ option.display }}
            </button>
          }
        </div>
        <mat-icon [class.show]="loadingStatus === 'success'" svgIcon="check"></mat-icon>
      </div>
    } @else {
      <!-- This means it is used in tracker detail or elsewhere -->
      <div class="slide-toggle-container" [style.width]="toggleWidth">
        <span>No</span>
        <oph-slide-toggle
          [checked]="control?.value === '1' ? true : false"
          (toggle)="onSlideToggle($event)"
        ></oph-slide-toggle>
        <span>Yes</span>
        <mat-icon [class.show]="loadingStatus === 'success'" svgIcon="check"></mat-icon>
      </div>
    }
  }

  @if (metric?.type === trackerMetricTypes.Select) {
    <tracker-detail-metric-select
      [buttonText]="state.valueChosen && control?.value ? control.value : 'Choose'"
      [options]="options || []"
      [buttonStyle]="selectButtonStyle || {height: '40px'}"
      [ngStyle]="selectStyle"
      [loadingStatus]="loadingStatus"
      (optionChange)="onUpdateValue($event)"
    ></tracker-detail-metric-select>
  }
}
