import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {ProjectTask, Subtask, TaskTemplate, TaskTrigger} from 'src/app/core/model/task';
import {TimezoneService} from 'src/app/services/timezone.service';
import * as uuid from 'uuid';
import {TaskRepeatTimeValidityData} from '../tasks/model/task-repeat-time-validity-data';
import {formatSelectedDay} from '../tasks/utils/format-selected-day';
import {TwdTimeValidationService} from './../tasks/utils/twd-time-validation.service';
import {minMaxValidator} from 'src/app/rewards/shared/rewards-dialog/min-max-validator';
import {REWARD_COST_MAX, REWARD_COST_MIN} from 'src/app/rewards/shared/rewards-constants';
@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(
    private formBuilder: NonNullableFormBuilder,
    private twdValidationService: TwdTimeValidationService,
    private timezoneService: TimezoneService
  ) {}

  today = formatSelectedDay(new Date());
  subtaskEditIndex: number;
  editingSubtask: boolean;
  defaultSchedule = {
    repeatType: 'week',
    repeatEvery: 1,
    repeatDays: [],
    dueWithin: 'project',
    dueWithinIntervalType: 'hours',
    dueWithinDate: this.today,
    scheduleType: 'doNotRepeat',
    scheduleOnDate: 'asap',
    endsType: 'never',
    endsDate: this.today,
    endsOccurrences: 10,
    eventStart: '',
    eventEnd: '',
    targetCompletionType: 'endOfProject',
    targetCompletionDate: '',
    targetCompletionValue: 1,
    targetCompletionValueType: 'hours',
    expirationType: 'endOfProject',
    expirationDate: '',
    expirationValue: 1,
    expirationValueType: 'hours',
  };

  getTaskForm(task?: ProjectTask, template?: boolean, defaultCoinId?: string): FormGroup {
    if (template) {
      return this.formBuilder.group({
        label: [task?.label || '', Validators.required],
        description: [task?.description || ''],
        icon: [task?.icon || 'alarm-clock'],
        duration: [task?.duration || 15],
        subtasks: this.formBuilder.array(
          task?.subtasks ? task.subtasks.map(subtask => this.getSubtaskFormGroup(subtask)) : []
        ),
      });
    }

    return this.formBuilder.group({
      event: [task?.event || false],
      label: [task?.label || '', Validators.required],
      description: [task?.description || ''],
      icon: [task?.icon || 'alarm-clock'],
      duration: [task?.duration || 15],
      priority: [task?.priority || 2],
      location: [task?.location || ''],
      autoReset: [task?.autoReset || false],
      schedule: this.formBuilder.group({
        repeatType: [task?.schedule?.repeatType || 'week'], // week, day
        repeatEvery: [task?.schedule?.repeatEvery || 1],
        repeatDays: [task?.schedule?.repeatDays || []],
        dueWithin: [task?.schedule?.dueWithin || 'project'],
        dueWithinIntervalType: [task?.schedule?.dueWithinIntervalType || 'hours'],
        dueWithinDate: [task?.schedule?.dueWithinDate || this.today],
        scheduleType: [task?.schedule?.scheduleType || 'doNotRepeat'],
        scheduleOnDate: [task?.schedule?.scheduleOnDate || 'asap'], // asap or date
        endsType: [task?.schedule?.endsType || 'never'], // never, date, occurrences
        endsDate: [task?.schedule?.endsDate || this.today], // never, date string
        endsOccurrences: [task?.schedule?.endsOccurrences || 10], // number
        // event times are in own formGroup for custom validation, formatted on finish
        eventTimes: this.formBuilder.group(
          {eventStart: [task?.schedule?.eventStart || ''], eventEnd: [task?.schedule?.eventEnd || '']},
          {validators: this.twdValidationService.validateTimes()}
        ),
        targetCompletionType: [task?.schedule?.targetCompletionType || 'endOfProject'],
        targetCompletionDate: [task?.schedule?.targetCompletionDate || ''],
        targetCompletionValue: [task?.schedule?.targetCompletionValue || 1],
        targetCompletionValueType: [task?.schedule?.targetCompletionValueType || 'hours'],
        expirationType: [task?.schedule?.expirationType || 'endOfProject'],
        expirationDate: [task?.schedule?.expirationDate || ''],
        expirationValue: [task?.schedule?.expirationValue || 1],
        expirationValueType: [task?.schedule?.expirationValueType || 'hours'],
      }),
      triggers: this.formBuilder.array(
        task?.triggers ? task.triggers.map(trigger => this.getTriggerFormGroup(trigger)) : []
      ),
      subtasks: this.formBuilder.array(
        task?.subtasks ? task.subtasks.map(subtask => this.getSubtaskFormGroup(subtask)) : []
      ),
      teams: [task?.teams || []],
      users: [task?.users || []],
      requirements: [task?.requirements || []],
      taskCoinType: [task?.taskCoinType || defaultCoinId || ''],
      taskCoinValue: [task?.taskCoinValue || 0, [minMaxValidator(REWARD_COST_MIN, REWARD_COST_MAX)]],
    });
  }

  getTriggerFormGroup(trigger?: TaskTrigger): AbstractControl {
    return this.formBuilder.group({
      type: [trigger?.type || 'metric'],
      id: [trigger?.id || uuid.v4()],
      taskInstanceId: [trigger?.taskInstanceId || ''],
      metricId: [trigger?.metricId || ''],
      trackerId: [trigger?.trackerId || ''],
      operator: [trigger?.operator || 'eq'],
      value: [trigger?.value || ''],
    });
  }

  getSubtaskFormGroup(subtask?: Subtask): AbstractControl {
    return this.formBuilder.group({
      _id: [subtask?._id || uuid.v4()],
      complete: [subtask?.complete || false],
      trackerId: [subtask?.trackerId || ''],
      metricIds: [subtask?.metricIds || []].map(metric =>
        typeof metric === 'string' ? {metricId: metric, readOnly: false} : metric
      ),
      body: [subtask?.body || ''],
      title: [subtask?.title || ''],
    });
  }

  setSubtaskEditIndex(index: number, editing?: boolean) {
    this.subtaskEditIndex = index;
    this.editingSubtask = editing || false;
  }

  getSubtaskEditIndex(): number {
    return this.subtaskEditIndex;
  }

  getEditingSubtask(): boolean {
    return this.editingSubtask;
  }

  formatProjectTaskFromForm(form: FormGroup): ProjectTask {
    const schedule = form.get('schedule');
    const eventEnd = schedule.value.eventTimes.eventEnd;
    const subtasks = form.get('subtasks').value;
    const label = form.get('label').value;
    const task = {
      ...form.value,
      schedule: {
        ...form.get('schedule').value,
        scheduleOnDate:
          schedule.value.scheduleOnDate === 'asap'
            ? 'asap'
            : this.timezoneService.convertDateToUtcString(schedule.value.scheduleOnDate),
        dueWithin: String(schedule.value.dueWithin),
        eventStart: schedule.value.eventTimes.eventStart,
        eventEnd: eventEnd === '12:00 am' ? '11:59 pm' : eventEnd,
      },
      subtasks: subtasks.length === 0 ? this.addSubtask(label) : subtasks,
    };
    if (task.taskCoinValue === 0) {
      delete task.taskCoinType;
      delete task.taskCoinValue;
    }
    delete task.schedule.eventTimes;
    return task;
  }

  formatTaskTemplateFromForm(form: FormGroup): TaskTemplate {
    const subtasks = form.get('subtasks').value;
    const label = form.get('label').value;
    const task = {
      ...form.value,
      subtasks: subtasks.length === 0 ? this.addSubtask(label) : subtasks,
    };
    // Templates do not have triggers or schedule - this is initilizing them if the template is created from a task
    task.triggers = [];
    task.schedule = this.defaultSchedule;
    task.event = false;
    return task;
  }

  addSubtask(label: string): Subtask[] {
    return [{_id: uuid.v4(), complete: false, body: '', title: label, trackerId: '', metricIds: [], notes: []}];
  }

  // checks if task expiration time is less than task repetition time, everything is converted to hours for comparison
  getRepeatTimeValidityData(
    scheduleControls: AbstractControl,
    valueType: string,
    value: number
  ): TaskRepeatTimeValidityData {
    const {repeatType, repeatEvery, repeatDays} = scheduleControls.value;
    const multiplier = this.getMultiplier(valueType);
    const totalTime = multiplier * value;

    if (repeatDays?.length === 0) {
      return {valid: true};
    }

    if (repeatType === 'day') {
      const timeBeforeNextTask = repeatEvery * 24;
      return {
        valid: timeBeforeNextTask >= totalTime,
        timeBeforeNextTask,
        timeBeforeNextTaskDisplay: this.formatDuration(timeBeforeNextTask),
        totalTime,
      };
    }
    // everything following assumes repeatType === 'week'
    let timeBeforeNextTask: number;
    if (repeatDays.length === 1) {
      // if there is only one day selected, hours in week * how many weeks
      timeBeforeNextTask = 24 * 7 * repeatEvery;
    }
    if (repeatDays.length > 1) {
      // if multiple days are selected, time between tasks + (how many weeks * hours in week)
      if (repeatEvery === 1) {
        const dayDifferenceBetweenRepeatingTasks = this.findSmallestDayDifferenceWithWraparound(repeatDays);
        timeBeforeNextTask = dayDifferenceBetweenRepeatingTasks * 24;
      }
      if (repeatEvery > 1) {
        const dayDifferenceBetweenRepeatingTasks = this.findSmallestDayDifference(repeatDays);
        timeBeforeNextTask = dayDifferenceBetweenRepeatingTasks * 24;
      }
    }
    return {
      valid: timeBeforeNextTask >= totalTime,
      timeBeforeNextTask,
      timeBeforeNextTaskDisplay: this.formatDuration(timeBeforeNextTask),
      totalTime,
    };
  }

  getMultiplier(type: string): number {
    switch (type) {
      case 'hours':
        return 1;
      case 'days':
        return 24;
      case 'weeks':
        return 24 * 7;
    }
  }

  findSmallestDayDifference(arr: number[]): number {
    arr.sort((a, b) => a - b);

    // Initialize the smallest difference with a large number.
    let smallestDifference = Infinity;

    // Iterate through the array to find the smallest difference between adjacent elements.
    for (let i = 1; i < arr.length; i++) {
      const difference = arr[i] - arr[i - 1];
      // Update the smallest difference if the current difference is smaller.
      if (difference < smallestDifference) {
        smallestDifference = difference;
      }
    }

    return smallestDifference;
  }

  findSmallestDayDifferenceWithWraparound(days: number[]): number {
    // Ensure the list is sorted to make comparison easier
    days.sort((a, b) => a - b);
    // Initialize the smallest difference to the maximum possible, 7 days
    let minDiff: number = 7;
    for (let i = 0; i < days.length - 1; i++) {
      // Direct difference
      const directDiff: number = days[i + 1] - days[i];
      // Difference that wraps around the end of the week
      const wrapAroundDiff: number = 7 - directDiff;
      // Update the smallest difference
      minDiff = Math.min(minDiff, directDiff, wrapAroundDiff);
    }
    // Special case for the first and last element, which might have a smaller wrap-around difference
    const firstLastDiff: number = Math.min(days[0] + 7 - days[days.length - 1], days[days.length - 1] + 7 - days[0]);
    minDiff = Math.min(minDiff, firstLastDiff);

    return minDiff;
  }

  formatDuration(hours: number): string {
    // Constants for conversion
    const hoursPerDay = 24;
    const daysPerWeek = 7;

    // Calculate the breakdown into weeks, days, and hours
    const weeks = Math.floor(hours / (hoursPerDay * daysPerWeek));
    const days = Math.floor((hours % (hoursPerDay * daysPerWeek)) / hoursPerDay);
    const remainingHours = hours % hoursPerDay;

    // Create an array of time components and their labels, excluding any with a value of 0
    const timeComponents = [
      {value: weeks, label: 'week'},
      {value: days, label: 'day'},
      {value: remainingHours, label: 'hour'},
    ]
      .filter(component => component.value !== 0)
      .map(component => `${component.value} ${component.label}${component.value > 1 ? 's' : ''}`);

    // Join the components with commas and return the result
    return timeComponents.join(', ') || '0 hours'; // Default to "0 hours" if all components are 0
  }

  checkIfTaskHasTrigger(task: ProjectTask): boolean {
    return task.triggers.some(trigger => trigger.trackerId && trigger.metricId);
  }

  checkIfTriggerIsComplete(trigger: TaskTrigger) {
    return trigger.metricId && !!trigger.value;
  }

  checkIfSubtaskIsComplete(subtask: Subtask): boolean {
    if (!subtask.trackerId) {
      return true;
    }
    return !!subtask.trackerId && !!subtask.metricIds.length;
  }

  getImgArrayFromHTMLString(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    const srcs = Array.from(imgElements).map(img => img.src);
    return srcs;
  }
}
