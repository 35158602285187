import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date): string {
    const date = moment(value);
    const now = moment();

    // If date is in the future or within last few seconds, return "now"
    if (date.isAfter(now) || now.diff(date, 'seconds') < 5) {
      return 'now';
    }

    return date.fromNow();
  }
}
