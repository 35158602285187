<header>
  <span class="dialog-title">Custom Metric Timestamp</span>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<div class="content-container">
  <button class="select-button" (click)="picker.open()">
    <span class="text">{{ form.get('date')?.value | date: 'MM/dd/yyyy' }}</span>
    <mat-icon svgIcon="chevron-down"></mat-icon>
  </button>
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="form.get('date')"
    [max]="maxDate"
    [min]="minDate"
    placeholder="Choose a date"
  />
  <mat-datepicker #picker></mat-datepicker>

  <div class="time-controls">
    <oph-select-menu
      [options]="hours"
      [buttonText]="form.get('hours')?.value || '12'"
      [buttonStyle]="selectButtonStyle"
      (optionClick)="onTimeChange('hour', $event)"
    >
    </oph-select-menu>

    <oph-select-menu
      [options]="minutes"
      [buttonText]="form.get('minutes')?.value || '00'"
      [buttonStyle]="selectButtonStyle"
      (optionClick)="onTimeChange('minute', $event)"
    >
    </oph-select-menu>

    <oph-select-menu
      [options]="ampm"
      [buttonText]="form.get('ampm')?.value || 'AM'"
      [buttonStyle]="selectButtonStyle"
      (optionClick)="onTimeChange('ampm', $event)"
    >
    </oph-select-menu>
  </div>
</div>

<div class="button-container">
  <oph-button
    color="gray"
    appearance="outline"
    [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
    (click)="onClose()"
    >Cancel</oph-button
  >
  <oph-button color="green" [style]="{'border-radius': '8px'}" (click)="onSave()">Save</oph-button>
</div>
