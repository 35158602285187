import {HttpClient, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_WARNING, IMAGE_UPLOAD_MAX_SIZE} from 'src/app/app.constants';
import {environment} from 'src/environments/environment';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  validateFile(file: File): string | null {
    if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      return ALLOWED_IMAGE_TYPES_WARNING;
    }
    if (file.size > IMAGE_UPLOAD_MAX_SIZE * 1024 * 1024) {
      return `Image size must be below ${IMAGE_UPLOAD_MAX_SIZE}MB`;
    }
    return null;
  }

  uploadFile(
    file: File,
    prefix: string = ''
  ): Observable<{
    progress: number;
    url?: string;
  }> {
    const uploadData = new FormData();
    const fileName = encodeURIComponent(`${prefix}${uuid.v4()}_${file.name}`);
    uploadData.append('file', file, fileName);

    return this.http
      .post(`${environment.apiUrl}/imageUpload`, uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((event.loaded / event.total) * 100);
            return {progress};
          }
          if (event.type === HttpEventType.Response) {
            return {
              progress: 100,
              url: event.body as string,
            };
          }
          return {progress: 0};
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}
