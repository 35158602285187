<oph-input-orange
  [control]="metricForm.get('name')"
  [inputStyle]="{
    height: '50px',
    borderRadius: '8px',
    fontSize: '18px',
    width: '100%'
  }"
  [orangeBorder]="true"
  placeholder="Metric Name"
></oph-input-orange>

<div class="row">
  <div class="label-container mr-10">
    <label>Type</label>
    <tracker-detail-metric-select
      [buttonText]="typeControl.value | trackerMetricTypeDisplay"
      [options]="typeOptions"
      [disabled]="editing"
      (optionChange)="onTypeChange($event)"
      class="mw-280"
    ></tracker-detail-metric-select>
  </div>
  <div class="checkbox-container">
    <oph-checkbox
      color="green"
      text="Editable"
      textOrientation="right"
      [control]="metricForm.get('updateable')"
      [textStyle]="{
        color: 'var(--dark)',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
        letterSpacing: '-0.3px'
      }"
      class="oph-checkbox-margin"
    ></oph-checkbox>
  </div>
</div>

@if (typeControl.value !== trackerMetricTypes.Select) {
  <div class="row">
    @if (typeControl.value !== trackerMetricTypes.Select) {
      <div class="label-container mr-10">
        <label>Default Value</label>
        <tracker-metric-value-update
          [metric]="metricForm.value"
          [control]="metricForm.get('defaultValue')"
          [inputStyle]="{
            height: '40px',
            'border-radius': '8px',
            width: '100%',
            'font-size': '18px',
            'font-weight': '400',
            color: 'var(--off-black)'
          }"
          [showNumericUpdateButton]="false"
          [options]="optionsFormArray?.value | trackerSelectOptions"
        ></tracker-metric-value-update>
      </div>
    }

    @if (typeControl.value === trackerMetricTypes.Numeric) {
      <div class="label-container short-label-container">
        <label>Suffix</label>
        <oph-input-orange
          [control]="metricForm.get('uom')"
          [inputStyle]="{
            height: '40px',
            'border-radius': '8px',
            'font-size': '18px',
            'font-weight': '400',
            color: 'var(--off-black)'
          }"
          [orangeBorder]="false"
          placeholder="lbs"
        ></oph-input-orange>
      </div>
    }
  </div>
}

@if (typeControl.value === trackerMetricTypes.Select) {
  <div class="label-container w-100p">
    <label>List Options</label>
    <div class="list-options-container">
      <span class="select-default-text" [class.tracker-detail-default-text]="trackerDetail">Default</span>
      @for (formGroup of optionsFormArray?.controls; track formGroup; let i = $index) {
        <div class="list-option-container">
          <oph-input-orange
            [control]="formGroup.controls.value"
            [inputStyle]="{
              height: '40px',
              'border-radius': '8px',
              'max-width': '300px',
              'font-size': '14px',
              'font-weight': '400',
              color: 'var(--brown-med)'
            }"
          ></oph-input-orange>

          <oph-checkbox
            (valueChange)="onSelectDefaultValueChange(i, $event)"
            [checked]="formGroup.value.default"
          ></oph-checkbox>
          <oph-x-button
            [class.hidden-x-button]="optionsFormArray?.length === 1"
            (click)="onRemoveOption(i)"
          ></oph-x-button>
        </div>
      }
      <oph-button
        color="orange"
        size="small"
        [style]="{padding: '0 12px', 'font-size': '14px', 'border-radius': '10px'}"
        (buttonClick)="onNewOption()"
        class="new-option-button"
      >
        <mat-icon svgIcon="plus" class="plus-yellow"></mat-icon>
        New Option
      </oph-button>
    </div>
  </div>
}

<!-- This might get added back in -->
<!-- @if (typeControl.value === 'numeric') {
  <div class="row">
    <div class="label-item mr-10" [class.long-label-item]="typeControl.value === 'select'">
      <label>MIN</label>
      <oph-input-orange
        [control]="metricForm.get('min')"
        [style]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        type="number"
      ></oph-input-orange>
    </div>
    <div class="label-item" [class.long-label-item]="typeControl.value === 'select'">
      <label>MAX</label>
      <oph-input-orange
        [control]="metricForm.get('max')"
        [style]="{
          height: '26px',
          'border-radius': '6px',
          'max-width': '142px',
          'font-size': '14px',
          'font-weight': '400',
          color: 'var(--brown-med)'
        }"
        type="number"
      ></oph-input-orange>
    </div>
  </div>
} -->
