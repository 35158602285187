import {Action} from '@ngrx/store';
import {ListSearchParams} from '../../model/list-search-params';
import {Token, TokenData} from '../../model/token';
import {HttpErrorResponse} from '@angular/common/http';

export enum TokensActionType {
  GET_ALL = '[Tokens] Get All',
  GET_ALL_SUCCESS = '[Tokens] Get All :: Success',

  GET_SINGLE = '[Tokens] Get Single',
  GET_SINGLE_SUCCESS = '[Tokens] Get Single :: Success',

  CREATE_NEW = '[Tokens] Create New',
  CREATE_NEW_SUCCESS = '[Tokens] Create New :: Success',

  UPDATE = '[Tokens] Update',
  UPDATE_SUCCESS = '[Tokens] Update :: Success',

  DUPLICATE = '[Tokens] Duplicate',
  DUPLICATE_SUCCESS = '[Tokens] Duplicate :: Success',

  DELETE = '[Tokens] Delete',

  UPDATE_FORM = '[Tokens] Update Form',

  CLEAR = '[Tokens] Clear',
}

export class GetAllTokensAction implements Action {
  public readonly type = TokensActionType.GET_ALL;

  public constructor(
    public payload: {
      params: ListSearchParams;
      onSuccess?: (tokenData: TokenData) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetAllTokensSuccessAction implements Action {
  public readonly type = TokensActionType.GET_ALL_SUCCESS;

  public constructor(public payload: {tokenData: TokenData}) {}
}

export class GetSingleTokenAction implements Action {
  public readonly type = TokensActionType.GET_SINGLE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (token: Token) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetSingleTokenSuccessAction implements Action {
  public readonly type = TokensActionType.GET_SINGLE_SUCCESS;

  public constructor(public payload: {token: Token}) {}
}

export class CreateNewTokenAction implements Action {
  public readonly type = TokensActionType.CREATE_NEW;

  public constructor(
    public payload: {
      token: Token;
      onSuccess?: (token: Token) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateNewTokenSuccessAction implements Action {
  public readonly type = TokensActionType.CREATE_NEW_SUCCESS;

  public constructor(public payload: {token: Token}) {}
}

export class UpdateTokenAction implements Action {
  public readonly type = TokensActionType.UPDATE;

  public constructor(
    public payload: {
      token: Token;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTokenSuccessAction implements Action {
  public readonly type = TokensActionType.UPDATE_SUCCESS;

  public constructor(public payload: {token: Token}) {}
}

export class DuplicateTokenAction implements Action {
  public readonly type = TokensActionType.DUPLICATE;

  public constructor(
    public payload: {
      tokenId: string;
      onSuccess?: (token: Token) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DuplicateTokenSuccessAction implements Action {
  public readonly type = TokensActionType.DUPLICATE_SUCCESS;

  public constructor(public payload: {token: Token}) {}
}

export class DeleteTokenAction implements Action {
  public readonly type = TokensActionType.DELETE;

  public constructor(
    public payload: {
      tokenId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ClearTokensAction implements Action {
  public readonly type = TokensActionType.CLEAR;
}

export type TokensAction =
  | GetAllTokensAction
  | GetAllTokensSuccessAction
  | GetSingleTokenAction
  | GetSingleTokenSuccessAction
  | CreateNewTokenAction
  | CreateNewTokenSuccessAction
  | UpdateTokenAction
  | UpdateTokenSuccessAction
  | DuplicateTokenAction
  | DuplicateTokenSuccessAction
  | DeleteTokenAction
  | ClearTokensAction;
