import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {AsyncPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, Input, SimpleChanges} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {BehaviorSubject, timer} from 'rxjs';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {MessageService} from 'src/app/services/message.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {ListViewWarningDialogComponent} from 'src/app/shared/list-views/shared/warning-dialog/list-view-warning-dialog.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackerMetricValueUpdateComponent} from 'src/app/shared/trackers/tracker-metric-value-update/tracker-metric-value-update.component';
import {OphLoadingModule} from '../../../shared/design/oph-loading/oph-loading.module';
import {TrackerMetricTypeDisplayPipe} from '../../shared/tracker-metric-type-display.pipe';
import {TrackerMetricTypes} from '../../shared/tracker-metric-types';
import {
  DeleteTrackerMetricAction,
  DuplicateTrackerMetricAction,
  UpdateTrackerAction,
  UpdateTrackerMetricValueAction,
} from './../../../core/store/trackers/trackers.action';
import {AddMetricDialogComponent} from './add-metric-dialog/add-metric-dialog.component';

@Component({
  selector: 'tracker-detail-metrics',
  standalone: true,
  imports: [
    OphButtonModule,
    MatIconModule,
    TrackerMetricTypeDisplayPipe,
    OphIconModule,
    ListViewsModule,
    AsyncPipe,
    OphLoadingModule,
    PipesModule,
    TrackerMetricValueUpdateComponent,
    DragDropModule,
  ],
  templateUrl: './tracker-detail-metrics.component.html',
  styleUrl: './tracker-detail-metrics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailMetricsComponent {
  @Input() tracker: Tracker;

  loadingMetricIndex$ = new BehaviorSubject<number | null>(null);
  loadingMetricUpdateIndex$ = new BehaviorSubject<number | null>(null);
  loadingMetricStatus$ = new BehaviorSubject<string>(null); // loading, success, null

  readonly dialog = inject(MatDialog);
  menuOptions = ['duplicate', 'delete'];
  metrics: TrackerMetric[];
  trackerMetricTypes = TrackerMetricTypes;
  currentValueControlArray = new FormArray([]);

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tracker && this.tracker) {
      this.metrics = this.tracker.metrics;
      this.metrics.forEach(metric => {
        this.currentValueControlArray.push(new FormControl(metric.value));
      });
    }
  }

  openMetricDialog(index?: number) {
    this.dialog.open(AddMetricDialogComponent, {
      backdropClass: 'oph-backdrop',
      panelClass: 'add-metric-dialog',
      data: {metric: index || index === 0 ? this.metrics[index] : null, trackerId: this.tracker._id},
      maxHeight: '90vh',
    });
  }

  onMenuAction(action: string, index: number) {
    const metric = this.metrics[index];
    if (action === 'duplicate') {
      this.loadingMetricIndex$.next(index);
      this.store$.dispatch(
        new DuplicateTrackerMetricAction({
          trackerId: this.tracker._id,
          metricId: metric._id,
          onSuccess: () => this.onSuccess(),
          onFailure: err => this.onDuplicateMetricFailure(err),
        })
      );
    }

    if (action === 'delete') {
      this.openDeleteConfirmation(metric, index);
    }
  }

  onDuplicateMetricFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem duplicating the metric.');
    this.loadingMetricIndex$.next(null);
  }

  openDeleteConfirmation(metric: TrackerMetric, index: number) {
    const dialog = this.dialog.open(ListViewWarningDialogComponent, {
      backdropClass: 'oph-backdrop',
      panelClass: 'list-views-warning-dialog',
      data: {type: 'metric', metric},
    });
    dialog.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.loadingMetricIndex$.next(index);
        this.store$.dispatch(
          new DeleteTrackerMetricAction({
            trackerId: this.tracker._id,
            metricId: metric._id,
            onSuccess: () => this.onSuccess(),
            onFailure: err => this.onDeleteMetricFailure(err),
          })
        );
      }
    });
  }

  onSuccess() {
    this.loadingMetricIndex$.next(null);
  }

  onDeleteMetricFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem deleting the metric.');
    this.loadingMetricIndex$.next(null);
  }

  onUpdateValue(value: string, index: number) {
    this.loadingMetricUpdateIndex$.next(index);
    this.loadingMetricStatus$.next('loading');
    this.store$.dispatch(
      new UpdateTrackerMetricValueAction({
        trackerId: this.tracker._id,
        metricId: this.tracker.metrics[index]._id,
        value,
        onSuccess: () => this.onUpdateMetricValueSuccess(),
        onFailure: err => this.onUpdateMetricValueFailure(err),
      })
    );
  }

  onUpdateMetricValueSuccess() {
    this.loadingMetricStatus$.next('success');
    timer(2000).subscribe(() => {
      this.loadingMetricStatus$.next(null);
      this.loadingMetricUpdateIndex$.next(null);
    });
  }

  onUpdateMetricValueFailure(err: HttpErrorResponse) {
    this.loadingMetricUpdateIndex$.next(null);
    this.loadingMetricStatus$.next(null);

    this.messageService.add(err.error || 'There was a problem updating the metric value.');
  }

  onDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.metrics, event.previousIndex, event.currentIndex);
    this.store$.dispatch(new UpdateTrackerAction({tracker: {...this.tracker, metrics: this.metrics}}));
  }
}
