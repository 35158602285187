import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {Subtask} from 'src/app/core/model/task';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {selectActiveUserRole} from 'src/app/core/store/active-user/active-user.selector';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphTimestampDialogComponent} from 'src/app/shared/dialog/oph-timestamp-dialog/oph-timestamp-dialog.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackerMetricValueUpdateComponent} from 'src/app/shared/trackers/tracker-metric-value-update/tracker-metric-value-update.component';
import {TaskLoadingState} from '../../shared/model/task-loading-state';
import {CurrentSkedTaskDetailValueDisplayComponent} from '../value-display/current-sked-task-detail-value-display.component';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';

@Component({
  selector: 'current-sked-task-detail-metric-update-list',
  standalone: true,
  imports: [
    OphButtonModule,
    TrackerMetricValueUpdateComponent,
    CurrentSkedTaskDetailValueDisplayComponent,
    OphIconModule,
    MatIconModule,
    AsyncPipe,
    PipesModule,
  ],
  templateUrl: './current-sked-task-detail-metric-update-list.component.html',
  styleUrl: './current-sked-task-detail-metric-update-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailMetricUpdateListComponent implements OnChanges {
  @Input() subtask: Subtask;
  @Input() transparentBg: boolean;
  @Input() loading: TaskLoadingState;
  @Input() subtaskIndex: number;

  @Output() updateMetrics = new EventEmitter<{
    metrics: {metricId: string; value: string}[];
    timestamp?: string;
  }>();

  form = new FormArray([]);
  readonly dialog = inject(MatDialog);
  TrackerMetricTypes = TrackerMetricTypes;
  showUpdateButton = false;
  filteredMetrics: TrackerMetric[] = [];
  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));

  timestamp$ = new BehaviorSubject<string>('');

  constructor(private store$: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subtask && this.subtask) {
      this.form.clear();

      if (this.subtask.tracker?.metrics?.length) {
        this.filteredMetrics = this.subtask.tracker.metrics.filter(m => m.updateable);
        this.subtask.tracker.metrics.forEach(metric => {
          this.addMetricFormItem(metric);
        });
        this.showUpdateButton = this.subtask.tracker?.metrics?.some(metric => !metric.readOnly && metric.updateable);
      }
    }
  }

  addMetricFormItem(metric: TrackerMetric) {
    this.form.push(
      new FormGroup({
        metricId: new FormControl(metric._id),
        value: new FormControl(null),
      })
    );
  }

  onUpdateMetrics() {
    const updateData: {
      metrics: {metricId: string; value: string}[];
      timestamp?: string;
    } = {
      metrics: this.form.value.map(item => ({
        metricId: item.metricId,
        value: item.value,
      })),
    };

    const currentTimestamp = this.timestamp$.value;
    if (currentTimestamp) {
      updateData.timestamp = currentTimestamp;
    }

    this.updateMetrics.emit(updateData);
  }

  openTimestampDialog() {
    const dialogRef = this.dialog.open(OphTimestampDialogComponent, {
      data: {timestamp: this.timestamp$.value || ''},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.timestamp$.next(result);
      }
    });
  }
}
