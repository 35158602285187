import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormArray} from '@angular/forms';
import {Store} from '@ngrx/store';
import {SubtaskEditMetricSelectionComponent} from '../../../subtasks/subtask-edit/metric-selection/subtask-edit-metric-selection.component';
import {UpdateTwdSubtaskViewAction} from 'src/app/core/store/projects/projects.action';

@Component({
  selector: 'twd-subtask-edit',
  templateUrl: './twd-subtask-edit.component.html',
  styleUrls: ['./twd-subtask-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdSubtaskEditComponent {
  @Input() formArray: FormArray;
  @Input() subtaskIndex: number;
  @Input() projectId: string;

  @Output() hideHeaderAndBorder = new EventEmitter<boolean>();
  @Output() back = new EventEmitter();
  @Output() imageAdded = new EventEmitter<string>();

  @ViewChild('metricSelection') metricSelection: SubtaskEditMetricSelectionComponent;

  view = 'subtask';

  constructor(private store$: Store) {}

  onConnectTracker() {
    this.setView('metric');
  }

  onMetricViewBack(initialMetricsState: {metricId: string; readOnly: boolean}[]) {
    // Restore initial state to the form
    if (this.formArray.value?.length && this.formArray.at(this.subtaskIndex)) {
      this.formArray.at(this.subtaskIndex).patchValue({
        metricIds: initialMetricsState.map(m => ({
          metricId: m.metricId,
          readOnly: m.readOnly,
        })),
      });
    }
    this.setView('subtask');
  }

  onMetricViewNext(metricIds: {metricId: string; readOnly: boolean}[]) {
    // Save array of selected metric IDs to the form
    this.formArray.at(this.subtaskIndex).patchValue({
      metricIds,
    });
    this.setView('subtask');
  }

  setView(view: string) {
    this.store$.dispatch(new UpdateTwdSubtaskViewAction({view}));
    this.view = view;
  }

  onCloseNewTrackerInDialog(metricIds: {metricId: string; readOnly: boolean}[], trackerId?: string) {
    if (trackerId) {
      this.formArray.at(this.subtaskIndex).patchValue({
        trackerId,
        metricIds,
      });
    }
    this.setView('subtask');
  }
}
