@if (
  {
    user: user$ | async,
    sked: sked$ | async,
    task: task$ | async,
    projectsMap: projectsMap$ | async,
    locationsMap: locationsMap$ | async,
    combinedLensSettingsMap: combinedLensSettingsMap$ | async,
    ableToCompleteSubtaskIndex: ableToCompleteSubtaskIndex$ | async,
    skedTimeStatus: skedTimeStatus$ | async,
    availableTaskActions: availableTaskActions$ | async,
    loading: loading$ | async,
    selectedSubtask: selectedSubtask$ | async,
    selectedSubtaskIndex: selectedSubtaskIndex$ | async,
    imageLoading: imageLoading$ | async,
    uploadProgress: uploadProgress$ | async,
    reversedNotes: reversedNotes$ | async,
    usersMap$: usersMap$ | async
  };
  as state
) {
  @if (state.loading.task) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (!state.selectedSubtask) {
      <!-- TASK VIEW -->
      <header>
        <div class="left-container">
          <oph-back-button (buttonClick)="onBack()"></oph-back-button>
        </div>

        @if (state.task.taskCoinValue && state.task.taskCoinValue > 0 && state.task.taskCoinType) {
          <div class="coin-container">
            {{ state.task.taskCoinValue }}
            <mat-icon [svgIcon]="(coinTypesMap$ | async)[state.task.taskCoinType]?.name | rewardCoinIcon"></mat-icon>
          </div>
        }
        <current-sked-task-detail-status-button
          [loading]="state.loading"
          [task]="state.task"
          (statusAction)="onUpdateTaskStatus($event)"
          [availableTaskActions]="state.availableTaskActions"
        ></current-sked-task-detail-status-button>
      </header>

      <div class="task-view-content">
        <div class="task-view-content-left-container">
          <div class="subtask-list-container">
            <div class="subtask-container">
              @for (subtask of state.task.subtasks; track subtask; let i = $index) {
                <current-sked-task-detail-subtask
                  [task]="state.task"
                  [subtask]="subtask"
                  [subtaskIndex]="i"
                  [ableToCompleteSubtaskIndex]="state.ableToCompleteSubtaskIndex"
                  [loading]="state.loading"
                  [class.subtask-hover-disabled]="state.ableToCompleteSubtaskIndex < i"
                  (subtaskClick)="onSubtask(subtask, i)"
                  (updateTaskStatus)="onUpdateTaskStatus($event)"
                  (updateSubtaskStatus)="onUpdateSubtaskStatus($event, subtask, i)"
                ></current-sked-task-detail-subtask>
              }
            </div>
          </div>
        </div>

        <div class="task-view-content-right-container">
          <div class="task-view-content-right-container-inner">
            <div class="right-container-header">
              <oph-icon [name]="'color-icons/' + state.task.icon" size="32" class="task-icon"></oph-icon>
              <h2>{{ state.task.label || '' }}</h2>
            </div>

            @if (state.task.description) {
              <p class="task-description">{{ state.task.description }}</p>
            }

            <div class="info-container">
              <div class="project text-overflow" [style.background]="state.projectsMap[state.task.projectId]?.color">
                <span>{{ state.projectsMap[state.task.projectId]?.label }}</span>
              </div>
              @if (!!state.task.location) {
                <div class="info-container-item location text-overflow">
                  <oph-icon name="pin-brown" size="15" class="location-icon"></oph-icon>
                  <span class="info-value">{{ (state.locationsMap || {})[state.task.location]?.name }}</span>
                </div>
              }
              @if (state.task.duration) {
                <div class="info-container-item duration">
                  <oph-icon name="clock-solid-brown" size="15" class="location-icon"></oph-icon>
                  <span class="info-value">{{ state.task.duration | minutesToHoursAndMinutes }}</span>
                </div>
              }
              @if (state.task | doesTaskHaveTrigger) {
                <img class="task-status-icon" src="assets/img/icons/bolt-2.svg" />
              }
            </div>
          </div>
        </div>
      </div>
    } @else {
      <!-- ****************************************************************************** -->
      <!-- ****************************** SUBTASK VIEW ************************************* -->
      <!-- ****************************************************************************** -->

      <!-- --------------------------------HEADER------------------------------------ -->
      <header class="subtask-view-header">
        <div class="subtask-view-header-back-button-container">
          <oph-back-button (buttonClick)="onSubtaskBack()"></oph-back-button>
        </div>
        <div class="subtask-view-header-inner-container">
          <div
            [matTooltip]="
              (state.selectedSubtask | hasUpdateableMetrics) && !state.selectedSubtask.complete
                ? 'You must update metrics to complete this subtask'
                : ''
            "
          >
            <current-sked-checkbox
              [checked]="state.selectedSubtask.complete"
              [loading]="state.loading.subtaskIndex"
              [disabled]="(state.selectedSubtask | hasUpdateableMetrics) && !state.selectedSubtask.complete"
              (checkboxClick)="onUpdateSubtaskStatus($event, state.selectedSubtask, 1)"
            ></current-sked-checkbox>
          </div>
          <span class="subtask-view-name">{{ state.selectedSubtask.title }} </span>
        </div>
      </header>

      <div class="subtask-view-content-container">
        <div class="right">
          <div class="subtask-view-container info">
            @if (state.task?.projectId && state.projectsMap[state.task.projectId]) {
              <div
                class="project-detail"
                [style.background]="state.projectsMap[state.task.projectId].color || 'transparent'"
              >
                <span>{{ state.projectsMap[state.task.projectId].label || 'Unknown' }}</span>
              </div>
            }
            @if (state.task?.location && state.locationsMap[state.task.location]) {
              <div class="location">
                <img src="assets/img/icons/pin-brown.svg" alt="location" />
                <span>{{ state.locationsMap[state.task.location]?.name }}</span>
              </div>
            }
          </div>
          @if (state.selectedSubtask | hasUpdateableMetrics: true) {
            <current-sked-task-detail-metric-update-list
              [subtask]="state.selectedSubtask"
              [subtaskIndex]="0"
              [loading]="state.loading"
              [transparentBg]="true"
              (updateMetrics)="
                onUpdateMetricValues($event.metrics, $event.timestamp, state.selectedSubtask, 0, state.task._id)
              "
            ></current-sked-task-detail-metric-update-list>
          }
          <div class="notes-container subtask-view-container">
            <div class="notes-container-header">
              <oph-icon name="file-brown" size="20"></oph-icon>
              <span>Notes</span>
            </div>
            <oph-input-orange
              [control]="noteForm.get('note')"
              [inputStyle]="{height: '42px', borderRadius: '6px', fontSize: '16px'}"
            ></oph-input-orange>
            <input
              type="file"
              class="file-input"
              (change)="onFileSelected($event)"
              #fileUpload
              accept="image/*"
              style="display: none"
            />
            @if (noteForm.get('images').value?.length) {
              <div class="image-previews">
                @for (image of noteForm.get('images').value; track image; let imageIndex = $index) {
                  <div class="image-preview-container" [class.loading]="state.imageLoading[imageIndex]">
                    <img [src]="tempImageUrls[imageIndex] || image" class="preview-image" />
                    @if (state.imageLoading[imageIndex]) {
                      <div class="loading-overlay">
                        <div class="oph-white-spinner">
                          <mat-spinner
                            class="avatar-spinner"
                            mode="determinate"
                            [value]="state.uploadProgress[imageIndex]"
                            [diameter]="30"
                          ></mat-spinner>
                        </div>
                      </div>
                    } @else {
                      <div class="delete-overlay">
                        <button class="delete-button" (click)="onDeleteImage(imageIndex)">
                          <oph-icon name="delete-white" size="20"></oph-icon>
                        </button>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            <div class="notes-button-container">
              <button class="upload-photo-button" (click)="onUploadPhotoClick()">
                Upload Photo
                <oph-icon name="camera-brown" size="16"></oph-icon>
              </button>
              <oph-button
                color="brown"
                size="medium"
                (buttonClick)="onPost()"
                [style]="{borderRadius: '6px'}"
                [loading]="notePostLoading$ | async"
                [disabled]="
                  (!noteForm.get('note').value && !noteForm.get('images').value?.length) ||
                  hasLoadingImages(state.imageLoading)
                "
                >Post</oph-button
              >
            </div>

            @for (note of state.reversedNotes; track note) {
              <div class="note">
                <span class="username">{{ (usersMap$ | async)[note.createdBy]?.username || 'Unknown' }}</span>
                <span class="time-ago">{{ note.createdAt | timeAgo }}</span>
                @if (note.note) {
                  <p class="note-text">{{ note.note }}</p>
                }
                @if (note.images?.length) {
                  <div class="note-images">
                    @for (image of note.images; track image) {
                      <img [src]="image" alt="Note image" class="note-image" (click)="openImageDialog(note)" />
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div class="subtask-view-container left current-sked-quill">
          @if (state.selectedSubtask.body) {
            <quill-view [content]="state.selectedSubtask.body"></quill-view>
          } @else {
            <div class="empty">This subtask has no instructions</div>
          }
        </div>
      </div>
    }
  }
}
