<button (click)="buttonClick.emit()" [ngStyle]="buttonStyle" [disabled]="loading">
  @if (loading) {
    <div class="oph-orange-spinner">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  } @else {
    @if (showMinus) {
      <mat-icon svgIcon="minus"></mat-icon>
    } @else {
      <mat-icon svgIcon="plus"></mat-icon>
    }
    <ng-content></ng-content>
  }
</button>
