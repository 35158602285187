import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {AddMetricToTrackerAction, UpdateTrackerMetricAction} from 'src/app/core/store/trackers/trackers.action';
import {MessageService} from 'src/app/services/message.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';
import {TrackersService} from 'src/app/shared/services/trackers.service';
import {TrackerDetailMetricComponent} from '../metric/tracker-detail-metric.component';

interface DialogData {
  metric?: TrackerMetric;
  trackerId: string;
}

@Component({
  selector: 'add-metric-dialog',
  standalone: true,
  imports: [OphIconModule, TrackerDetailMetricComponent, OphButtonModule, OphXButtonComponent, AsyncPipe],
  templateUrl: './add-metric-dialog.component.html',
  styleUrl: './add-metric-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMetricDialogComponent implements OnDestroy {
  readonly dialogRef = inject(MatDialogRef<AddMetricDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);

  loading$ = new BehaviorSubject<boolean>(false);

  metricForm: FormGroup;
  formSubscription: Subscription;
  valid: boolean;

  constructor(
    private trackersService: TrackersService,
    private store$: Store,
    private messageService: MessageService
  ) {
    this.metricForm = this.trackersService.getMetricsFormGroup(this.data?.metric || ({} as TrackerMetric));
    this.formSubscription = this.metricForm.valueChanges.subscribe(
      form => (this.valid = this.trackersService.isMetricFormValid(form))
    );
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  onAddMetric() {
    this.loading$.next(true);
    const metricDto = this.trackersService.formatMetricDto(this.metricForm);

    if (this.data.metric) {
      metricDto._id = this.data.metric._id;
      this.updateMetric(metricDto);
    } else {
      this.addNewMetric(metricDto);
    }
  }

  addNewMetric(metric: TrackerMetric) {
    this.store$.dispatch(
      new AddMetricToTrackerAction({
        trackerId: this.data.trackerId,
        metric,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  updateMetric(metric: TrackerMetric) {
    this.store$.dispatch(
      new UpdateTrackerMetricAction({
        trackerId: this.data.trackerId,
        metric,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.onClose();
    this.loading$.next(false);
  }

  onFailure(err: Error) {
    this.loading$.next(false);
    this.messageService.add(err.message || 'There was a problem adding the metric.');
  }
}
