<div class="metrics-list-container" [class.transparent-bg]="transparentBg" (click)="$event.stopPropagation()">
  @for (metric of filteredMetrics; track metric._id; let i = $index) {
    @if (metric.readOnly) {
      <div class="metric-title-container mb-16">
        <span class="metric-name">{{ metric?.name || '' }}</span>
        <span class="read-only-value"
          >current value:
          {{
            metric.type === TrackerMetricTypes.Boolean ? (metric.value | numberToBoolean) : metric.value || 'no value'
          }}
          {{ metric.value ? metric.uom || '' : '' }}</span
        >
      </div>
    } @else {
      <div class="metric">
        <div class="metric-title-container">
          <span class="metric-name">{{ metric?.name || '' }}</span>
          <current-sked-task-detail-value-display
            class="desktop"
            [metric]="metric"
          ></current-sked-task-detail-value-display>
        </div>
        <tracker-metric-value-update
          [metric]="metric"
          [subtaskId]="subtask._id"
          [control]="form.at(i).get('value')"
          [options]="metric.options"
          [inputStyle]="{width: '100%', flexGrow: '1', height: '38px', fontSize: '16px', borderRadius: '6px'}"
          [selectButtonStyle]="{fontSize: '16px', height: '40px', color: 'var(--off-black)'}"
          [booleanButtonStyle]="{width: '70px', fontSize: '16px', color: 'var(--off-black)', borderRadius: '6px'}"
          [suffix]="metric.uom || ''"
          toggleWidth="178px"
          (click)="$event.stopPropagation()"
        ></tracker-metric-value-update>
        <current-sked-task-detail-value-display
          [metric]="metric"
          class="mobile"
        ></current-sked-task-detail-value-display>
      </div>
    }
  }
  @if (showUpdateButton) {
    <div class="update-button-container">
      <oph-button
        color="green"
        class="update-button"
        (buttonClick)="onUpdateMetrics()"
        [style]="{width: '100%', borderRadius: '6px'}"
        [loading]="loading?.subtaskIndex === subtaskIndex && loading?.metricStatus === 'loading'"
      >
        @if (loading?.subtaskIndex === subtaskIndex && loading?.metricStatus === 'success') {
          <oph-icon name="check-white" size="20"></oph-icon>
        } @else {
          Update
        }
      </oph-button>
      @if ((userRole$ | async) === 'admin') {
        <button class="timestamp-button" (click)="openTimestampDialog()">
          <mat-icon svgIcon="clock" [class.green-icon]="!!(timestamp$ | async)"></mat-icon>
        </button>
      }
    </div>
  }
</div>
