<div class="dialog-container">
  <div class="header">
    <span class="username">{{ (usersMap$ | async)[note.createdBy]?.username || 'Unknown' }}</span>
    <span class="time-ago">{{ note.createdAt | timeAgo }}</span>
    <oph-x-button class="close-button" (buttonClick)="dialogRef.close()"></oph-x-button>
  </div>

  <div class="carousel-container">
    <div class="image-container">
      <img [src]="note.images[currentIndex]" [@slideAnimation]="currentIndex" alt="Note image" />
    </div>

    @if (note.images.length > 1) {
      <button
        class="nav-button prev"
        (click)="onPrevious()"
        [disabled]="currentIndex === 0"
        [class.disabled]="currentIndex === 0"
      >
        <oph-icon
          name="chevron-right-white"
          size="20"
          style="transform: rotate(180deg)"
          [style.margin-right.px]="2"
        ></oph-icon>
      </button>

      <button
        class="nav-button next"
        (click)="onNext()"
        [disabled]="currentIndex === note.images.length - 1"
        [class.disabled]="currentIndex === note.images.length - 1"
      >
        <oph-icon name="chevron-right-white" size="20" [style.margin-left.px]="2"></oph-icon>
      </button>

      <div class="image-counter">{{ currentIndex + 1 }} / {{ note.images.length }}</div>
    }
  </div>

  @if (note.note) {
    <p class="note-text">{{ note.note }}</p>
  }
</div>
