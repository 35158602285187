import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToBoolean',
})
export class NumberToBooleanPipe implements PipeTransform {
  transform(value: string): string {
    if (value === '1') {
      return 'Yes';
    }
    if (value === '0') {
      return 'No';
    }
    return 'no value';
  }
}
