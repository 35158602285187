<div
  class="container"
  [class.focus]="inputFocusClass"
  [style.width]="width + 'px' || '100%'"
  [style.height.px]="height || 44"
  [class.hovered]="hovered && !inputFocusClass"
>
  <input
    #input
    [formControl]="control"
    (input)="onInput($event.target.value)"
    (focus)="inputFocusClass = true"
    (blur)="inputFocusClass = false"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    [type]="inputType"
    [placeholder]="placeholder"
    [style.fontSize.px]="fontSize"
    [style.height.px]="height || 44"
  />
  @if (hovered && !inputFocusClass) {
    <div class="icon-container" [style.height.px]="height ? height - 2 : 44 - 2">
      <oph-icon name="pencil-brown"></oph-icon>
    </div>
  }
</div>
