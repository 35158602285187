import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, combineLatest, timer} from 'rxjs';
import {filter, map, take, withLatestFrom} from 'rxjs/operators';
import {Project} from 'src/app/core/model/project';
import {RewardCoin} from 'src/app/core/model/reward';
import {CurrentSkedTask, Subtask, SubtaskNote} from 'src/app/core/model/task';
import {User} from 'src/app/core/model/user';
import {selectActiveUser} from 'src/app/core/store/active-user/active-user.selector';
import {
  selectCurrentSkedDetailTask,
  selectCurrentSkedSkeds,
} from 'src/app/core/store/current-sked/current-sked.selector';
import {GetLensSettingsAction} from 'src/app/core/store/lenses/lenses.action';
import {GetLocationsAction} from 'src/app/core/store/locations/locations.action';
import {selectLocationsMap} from 'src/app/core/store/locations/locations.selector';
import {GetProjectsAction} from 'src/app/core/store/projects/projects.action';
import {selectProjectViewTaskId, selectProjectsMap} from 'src/app/core/store/projects/projects.selector';
import {GetCoinTypesAction} from 'src/app/core/store/rewards/rewards.action';
import {selectCoinTypesMap} from 'src/app/core/store/rewards/rewards.selector';
import {selectRouterParam} from 'src/app/core/store/router/router.selector';
import {LensSettings} from 'src/app/lenses/lens-settings';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';
import {Location} from 'src/app/locations/shared/model/location';
import {MessageService} from 'src/app/services/message.service';
import {CurrentSkedService} from '../shared/current-sked.service';
import {AvailableTaskActions} from '../shared/model/available-task-actions';
import {Sked} from '../shared/model/sked';
import {EMPTY_TASK_LOADING_STATE, TaskLoadingState} from '../shared/model/task-loading-state';
import {TaskStatusActions} from '../shared/task-status-actions';
import {TaskStatuses} from '../shared/task-statuses';
import {getSkedTimeStatus} from '../shared/utils/get-sked-time-status';
import {
  AddSubtaskNoteAction,
  ClearCurrentSkedTaskAction,
  ClearCurrentSkedValueChosen,
  CurrentSkedChangeSubtaskStatusAction,
  CurrentSkedChangeTaskStatusAction,
  CurrentSkedUpdateSubtaskMetricsAction,
  GetCurrentSkedTaskAction,
} from './../../core/store/current-sked/current-sked.action';
import {selectCombinedLensSettingsMap, selectLensSettingsMap} from './../../core/store/lenses/lenses.selector';
import {FileUploadService} from 'src/app/services/file-upload.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DeleteImageAction} from 'src/app/core/store/active-user/active-user.action';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {MatDialog} from '@angular/material/dialog';
import {CurrentSkedTaskDetailSubtaskImageDialogComponent} from './subtask-image-dialog/current-sked-task-detail-subtask-image-dialog.component';

export interface UpdateSubtaskMetric {
  metricId: string;
  value: string;
  timestamp?: string;
}

@Component({
  selector: 'current-sked-task-detail',
  templateUrl: './current-sked-task-detail.component.html',
  styleUrls: ['./current-sked-task-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout oph-feature-padding';

  @Input() notInSked: boolean; // if this component is used in project viewer, this will be true

  @Output() notInSkedBack = new EventEmitter();
  @Output() notInSkedGet = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: ElementRef;

  metricTimerSubscription: Subscription;

  user$: Observable<User> = this.store$.pipe(select(selectActiveUser));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  skeds$: Observable<Sked[]> = this.store$.pipe(select(selectCurrentSkedSkeds));
  sked$: Observable<Sked> = this.getSked();
  skedTimeStatus$: Observable<string> = this.store$.pipe(select(selectRouterParam('skedStatus')));
  skedIndex$: Observable<string> = this.store$.pipe(select(selectRouterParam('skedIndex')));
  skedId$: Observable<string> = this.store$.pipe(select(selectRouterParam('skedId')));
  taskId$: Observable<string> = this.store$.pipe(select(selectRouterParam('taskId')));
  storedTaskId$: Observable<string> = this.store$.pipe(select(selectProjectViewTaskId));
  task$: Observable<CurrentSkedTask> = this.store$.pipe(select(selectCurrentSkedDetailTask));
  projectsMap$: Observable<Record<string, Project>> = this.store$.pipe(select(selectProjectsMap));
  locationsMap$: Observable<Record<string, Location>> = this.store$.pipe(select(selectLocationsMap));
  lensSettingsMap$: Observable<Record<LensSettings, LensSetting>> = this.store$.pipe(select(selectLensSettingsMap));
  combinedLensSettingsMap$: Observable<Record<LensSettings, string>> = this.store$.pipe(
    select(selectCombinedLensSettingsMap)
  );
  ableToCompleteSubtaskIndex$: Observable<number> = this.observeCombinedLensSettingsMap();
  availableTaskActions$: Observable<AvailableTaskActions> = this.observeTask();
  coinTypesMap$: Observable<Record<string, RewardCoin>> = this.store$.pipe(select(selectCoinTypesMap));

  loading$ = new BehaviorSubject<TaskLoadingState>(EMPTY_TASK_LOADING_STATE);
  selectedSubtask$ = new BehaviorSubject<Subtask>(null);
  selectedSubtaskIndex$ = new BehaviorSubject<number>(null);

  descriptionOpen: boolean = false;
  taskStatuses = TaskStatuses;
  noteForm = new FormGroup({
    taskId: new FormControl(''),
    subtaskId: new FormControl(''),
    trackerId: new FormControl(''),
    images: new FormControl<string[]>([]),
    note: new FormControl(''),
  });

  uploadProgress$ = new BehaviorSubject<{[key: number]: number}>({});
  imageLoading$ = new BehaviorSubject<{[key: number]: boolean}>({});

  tempImageUrls: {[key: number]: string} = {};

  notePostLoading$ = new BehaviorSubject<boolean>(false);

  reversedNotes$ = new BehaviorSubject<SubtaskNote[]>([]);

  constructor(
    private messageService: MessageService,
    private router: Router,
    private store$: Store,
    private currentSkedService: CurrentSkedService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store$.dispatch(new GetProjectsAction({}));
    this.store$.dispatch(new GetLensSettingsAction({}));
    this.store$.dispatch(new GetLocationsAction({}));
    this.store$.dispatch(new GetCoinTypesAction({}));

    this.getTask();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(new ClearCurrentSkedTaskAction());
    // This clears the state used in the tracker metric update component
    this.store$.dispatch(new ClearCurrentSkedValueChosen());
  }

  observeTask(): Observable<AvailableTaskActions> {
    return this.task$.pipe(
      filter(task => !!task),
      withLatestFrom(this.user$, this.combinedLensSettingsMap$, this.skedTimeStatus$),
      map(([task, user, lensMap, skedTimeStatus]) => {
        return this.currentSkedService.getAvailableTaskActions(task, user, lensMap, skedTimeStatus);
      })
    );
  }

  observeCombinedLensSettingsMap(): Observable<number> {
    return combineLatest([this.task$, this.combinedLensSettingsMap$]).pipe(
      map(([task, settingsMap]) => {
        if (!task || !settingsMap) {
          return;
        }
        if (!task.authorizationObject?.isAuthorized) {
          return -1;
        }
        if (settingsMap[LensSettings.CanSkipSubtasks] === 'false') {
          let index;
          task.subtasks.forEach((subtask, i) => {
            if (subtask.complete) {
              index = i + 1;
            }
          });
          return index || 0;
        } else {
          return task.subtasks.length;
        }
      })
    );
  }

  getTask() {
    combineLatest([this.taskId$, this.storedTaskId$])
      .pipe(
        map(([id, storedId]) => id || storedId),
        filter(id => !!id),
        take(1)
      )
      .subscribe(taskId => {
        this.store$.dispatch(
          new GetCurrentSkedTaskAction({
            taskId,
            onSuccess: (task: CurrentSkedTask) => this.onGetTaskSuccess(task),
            onFailure: () => this.onGetTaskFailure(),
          })
        );
      });
  }

  onGetTaskSuccess(task: CurrentSkedTask) {
    this.loading$.next({...this.loading$.value, task: false, metricId: '', subtaskIndex: null, action: false});
  }

  onGetTaskFailure() {
    this.loading$.next({...this.loading$.value, task: false});
    this.messageService.add('There was a problem getting this task.');
    if (this.notInSked) {
      return this.notInSkedBack.emit();
    }
    timer(10).subscribe(() => this.router.navigate(['current-sked']));
  }

  getSked(): Observable<Sked> {
    return this.skeds$.pipe(
      filter(skeds => !!skeds.length),
      map(skeds => skeds.find(sked => sked.current))
    );
  }

  getSkedStatus(): Observable<string> {
    return this.sked$.pipe(map(sked => getSkedTimeStatus(sked?.startDateTime, sked?.endDateTime)));
  }

  onBack() {
    if (this.notInSked) {
      return this.notInSkedBack.emit();
    }
    this.router.navigate(['current-sked']);
  }

  onSubtaskBack() {
    this.selectedSubtask$.next(null);
    this.selectedSubtaskIndex$.next(null);
  }

  onSubtask(subtask: Subtask, index: number) {
    this.task$.pipe(take(1)).subscribe(task => {
      this.noteForm.patchValue({
        taskId: task._id,
        subtaskId: subtask._id || '',
        trackerId: subtask.trackerId || null,
        images: [],
        note: '',
      });
    });
    this.updateReversedNotes(subtask);
    this.selectedSubtask$.next(subtask);
    this.selectedSubtaskIndex$.next(index);
    this.scrollToTop();
  }

  onUpdateTaskStatus(actionType: TaskStatusActions) {
    combineLatest([this.task$, this.skedId$])
      .pipe(take(1))
      .subscribe(([task, skedId]) => {
        this.loading$.next({...this.loading$.value, action: true});
        this.store$.dispatch(
          new CurrentSkedChangeTaskStatusAction({
            id: task._id,
            actionType,
            skedId,
            onSuccess: task => {
              this.onActionSuccess(actionType, task);
            },
            onFailure: () => this.onActionFailure(actionType),
          })
        );
      });
  }

  checkIfTaskComplete(task: CurrentSkedTask) {
    if (task?.status === this.taskStatuses.Completed) {
      if (this.notInSked) {
        return;
      }
      this.messageService.add('Task is complete');
      this.router.navigate(['current-sked']);
    }
  }

  onActionSuccess(actionType: TaskStatusActions, task: CurrentSkedTask) {
    this.notInSkedGet.emit();
    this.checkIfTaskComplete(task);
    this.loading$.next({...this.loading$.value, action: false});
  }

  onActionFailure(actionType: TaskStatusActions) {
    const statusText = this.getFailureStatusText(actionType);
    this.messageService.add(`There was a problem ${statusText} the task.`);
    this.loading$.next({...this.loading$.value, action: false});
  }

  getSuccessStatusText(status: string): string {
    if (status === TaskStatuses.InProgress) {
      return 'started';
    }
    return status;
  }

  getFailureStatusText(actionType: TaskStatusActions): string {
    switch (actionType) {
      case TaskStatusActions.Start:
        return 'starting';
      case TaskStatusActions.Pause:
        return 'pausing';
      case TaskStatusActions.Complete:
        return 'completing';
    }
  }

  onUpdateMetricValues(
    metrics: UpdateSubtaskMetric[],
    timestamp: string,
    subtask: Subtask,
    subtaskIndex: number,
    taskId: string
  ) {
    this.loading$.next({
      ...this.loading$.value,
      subtaskIndex,
      metricStatus: 'loading',
    });
    this.store$.dispatch(
      new CurrentSkedUpdateSubtaskMetricsAction({
        trackerId: subtask.trackerId,
        taskId,
        metrics,
        subtaskId: subtask._id,
        timestamp,
        onSuccess: task => this.onUpdateMetricValueSuccess(task),
        onFailure: () => this.onUpdateMetricValueFailure(),
      })
    );
  }

  onUpdateMetricValueSuccess(task: CurrentSkedTask) {
    this.loading$.next({...this.loading$.value, metricStatus: 'success'});
    // Unsubscribe from the previous timer if it's still active
    if (this.metricTimerSubscription) {
      this.metricTimerSubscription.unsubscribe();
    }
    this.metricTimerSubscription = timer(1000).subscribe(() => {
      this.loading$.next({...this.loading$.value, metricStatus: '', subtaskIndex: null});
      this.checkIfTaskComplete(task);
      if (this.selectedSubtask$.value) {
        const subtaskIndex = task.subtasks.findIndex(subtask => !subtask.complete);
        if (subtaskIndex || subtaskIndex === 0) {
          this.selectedSubtask$.next(task.subtasks[subtaskIndex]);
          this.scrollToTop();
        } else {
          this.messageService.add('Subtask was not found. Defaulting to first subtask.');
        }
      }
    });
  }

  scrollToTop() {
    const hostElement = document.querySelector('current-sked-task-detail');
    hostElement?.scrollTo({top: 0});
  }

  onUpdateMetricValueFailure() {
    this.loading$.next({...this.loading$.value, metricStatus: '', subtaskIndex: null});
    this.messageService.add('There was a problem updating the metric value.');
  }

  onUpdateSubtaskStatus(complete: boolean, subtask: Subtask, subtaskIndex: number) {
    this.loading$.next({...this.loading$.value, subtaskIndex, checkbox: true});
    this.task$.pipe(take(1)).subscribe(task => {
      this.store$.dispatch(
        new CurrentSkedChangeSubtaskStatusAction({
          taskId: task._id,
          subtaskId: subtask._id,
          complete,
          onSuccess: (task: CurrentSkedTask) => {
            this.onChangeSubtaskStatusSuccess(task);
          },
          onFailure: () => this.onChangeSubtaskStatusFailure(),
        })
      );
    });
  }

  onChangeSubtaskStatusSuccess(task: CurrentSkedTask) {
    // figure out what's being sent from backend to need this
    if (task?.error) {
      this.messageService.add(task.error);
    }
    this.checkIfTaskComplete(task);
    this.loading$.next({...this.loading$.value, subtaskIndex: null, checkbox: false});
    if (this.selectedSubtask$.value) {
      const updatedSubtask = task.subtasks.find(subtask => !subtask.complete);
      if (updatedSubtask) {
        this.selectedSubtask$.next(updatedSubtask);
        this.updateReversedNotes(updatedSubtask);
      }
    }
    // this.notInSkedGet.emit();
  }

  onChangeSubtaskStatusFailure() {
    this.messageService.add('There was a problem updating the subtask.');
    this.loading$.next({...this.loading$.value, subtaskIndex: null, checkbox: false});
  }

  onUploadPhotoClick() {
    this.fileUpload.nativeElement.click();
  }

  private clearLoadingStates(index: number) {
    const currentLoadingState = {...this.imageLoading$.value};
    const currentProgress = {...this.uploadProgress$.value};
    delete currentLoadingState[index];
    delete currentProgress[index];

    this.imageLoading$.next(currentLoadingState);
    this.uploadProgress$.next(currentProgress);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      const validationError = this.fileUploadService.validateFile(file);
      if (validationError) {
        this.messageService.add(validationError);
        input.value = ''; // Reset the input after validation error
        return;
      }

      const currentImages = this.noteForm.get('images').value || [];
      const newImageIndex = currentImages.length;

      // Create temporary preview URL
      this.tempImageUrls[newImageIndex] = URL.createObjectURL(file);

      this.noteForm.patchValue({
        images: [...currentImages, ''],
      });

      this.imageLoading$.next({
        ...this.imageLoading$.value,
        [newImageIndex]: true,
      });

      this.fileUploadService.uploadFile(file).subscribe({
        next: result => {
          if ('progress' in result && !('url' in result)) {
            // Progress event
            this.uploadProgress$.next({
              ...this.uploadProgress$.value,
              [newImageIndex]: result.progress,
            });
          } else if ('url' in result) {
            // Complete event with URL
            this.uploadProgress$.next({
              ...this.uploadProgress$.value,
              [newImageIndex]: 100,
            });

            // Update the form with the final URL
            this.noteForm.patchValue({
              images: [...currentImages, result.url],
            });

            // Preload the actual image before removing the preview
            const img = new Image();
            img.onload = () => {
              // Only remove the preview once the actual image is loaded
              URL.revokeObjectURL(this.tempImageUrls[newImageIndex]);
              delete this.tempImageUrls[newImageIndex];
            };
            img.src = result.url;

            this.clearLoadingStates(newImageIndex);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add(error.message || 'There was a problem uploading the image.');
          this.clearLoadingStates(newImageIndex);
          // Clean up temp URL on error
          URL.revokeObjectURL(this.tempImageUrls[newImageIndex]);
          delete this.tempImageUrls[newImageIndex];
        },
        complete: () => {
          // Reset the input value after the upload is complete
          input.value = '';
        },
      });
    }
  }

  onDeleteImage(index: number) {
    const images = [...this.noteForm.get('images').value];
    const imageToDelete = images[index];
    images.splice(index, 1);
    this.noteForm.patchValue({images});

    // Revoke temporary URL if it exists
    if (this.tempImageUrls[index]) {
      URL.revokeObjectURL(this.tempImageUrls[index]);
      delete this.tempImageUrls[index];
    }

    // Only dispatch delete action if we have a valid image URL to delete
    if (imageToDelete) {
      this.store$.dispatch(
        new DeleteImageAction({
          ids: encodeURIComponent(imageToDelete),
        })
      );
    }
  }

  onPost() {
    this.notePostLoading$.next(true);
    this.store$.dispatch(
      new AddSubtaskNoteAction({
        subtaskNote: this.noteForm.value as SubtaskNote,
        onSuccess: (task: CurrentSkedTask) => this.onPostSuccess(task),
        onFailure: () => this.onPostFailure(),
      })
    );
  }

  onPostSuccess(task: CurrentSkedTask) {
    this.noteForm.patchValue({
      images: [],
      note: '',
    });

    const updatedSubtask = task.subtasks.find(s => s._id === this.selectedSubtask$.value._id);
    if (updatedSubtask) {
      this.selectedSubtask$.next(updatedSubtask);
      this.updateReversedNotes(updatedSubtask);
    }

    this.notePostLoading$.next(false);
    // Reset any remaining temp URLs
    Object.keys(this.tempImageUrls).forEach(key => {
      URL.revokeObjectURL(this.tempImageUrls[key]);
      delete this.tempImageUrls[key];
    });
    // Reset loading and progress states
    this.imageLoading$.next({});
    this.uploadProgress$.next({});
  }

  onPostFailure() {
    this.notePostLoading$.next(false);
  }

  openImageDialog(subtaskNote: SubtaskNote) {
    this.dialog.open(CurrentSkedTaskDetailSubtaskImageDialogComponent, {
      data: subtaskNote,
      width: '100%',
      maxWidth: '720px',
      height: '100%',
      maxHeight: '615px',
    });
  }

  private updateReversedNotes(subtask: Subtask) {
    this.reversedNotes$.next([...(subtask.notes || [])].reverse());
  }

  hasLoadingImages(imageLoading: {[key: number]: boolean}): boolean {
    return Object.values(imageLoading || {}).some(loading => loading);
  }
}
