import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {SkillModel} from 'src/app/core/model/skill';
import {User} from 'src/app/core/model/user';
import {selectSkillsList} from 'src/app/core/store/skills/skills.selector';
import {selectAllTeams} from 'src/app/core/store/teams/teams.selector';
import {selectAllUsers} from 'src/app/core/store/users/users.selector';
import {Team} from 'src/app/teams/shared/model/team';

@Component({
  selector: 'task-dialog-requirements',
  templateUrl: './task-dialog-requirements.component.html',
  styleUrls: ['./task-dialog-requirements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogRequirementsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;

  @Output() back = new EventEmitter();

  requirements$: Observable<SkillModel[]>;
  teams$: Observable<Team[]>;
  users$: Observable<User[]>;

  storedUsers: User[];
  storedTeams: Team[];
  storedRequirements: SkillModel[];

  selectedRequirements$ = new BehaviorSubject<Record<string, Record<string, boolean>>>({});

  buttonOptions = ['people', 'teams', 'skills'];
  selectedOption: 'people' | 'teams' | 'skills' = 'people';

  constructor(private store$: Store) {}

  ngOnInit() {
    this.requirements$ = this.store$.pipe(select(selectSkillsList));
    this.teams$ = this.store$.pipe(select(selectAllTeams));
    this.users$ = this.store$.pipe(select(selectAllUsers));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.storedUsers = this.usersControl.value;
      this.storedTeams = this.teamsControl.value;
      this.storedRequirements = this.requirementsControl.value;
    }
  }

  onOption(checked: boolean, id: string, type: string) {
    let control: AbstractControl;
    if (type === 'teams') {
      control = this.teamsControl;
    } else if (type === 'users') {
      control = this.usersControl;
    } else {
      control = this.requirementsControl;
    }

    if (!checked) {
      control.patchValue([...control.value].filter(v => v !== id));
    } else {
      control.patchValue([...control.value, id]);
    }
  }

  onCancel() {
    // this.usersControl.setValue(this.storedUsers);
    // this.teamsControl.setValue(this.storedTeams);
    // this.requirementsControl.setValue(this.storedRequirements);
    this.back.emit();
  }

  get usersControl(): AbstractControl {
    return this.form.get('users');
  }

  get teamsControl(): AbstractControl {
    return this.form.get('teams');
  }

  get requirementsControl(): AbstractControl {
    return this.form.get('requirements');
  }
}
