import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormArray} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Subtask} from 'src/app/core/model/task';
import {TasksService} from 'src/app/shared/services/tasks.service';
import {SubtaskEditMetricSelectionComponent} from '../../../subtasks/subtask-edit/metric-selection/subtask-edit-metric-selection.component';
import {SubtaskEditComponent} from '../../../subtasks/subtask-edit/subtask-edit.component';
import {TaskDialogPanels} from '../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-subtask-edit',
  templateUrl: './task-dialog-subtask-edit.component.html',
  styleUrls: ['./task-dialog-subtask-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogSubtaskEditComponent implements OnChanges {
  @Input() subtaskFormArray: FormArray;
  @Input() fromTemplate: boolean;
  @Input() projectId: string;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();
  @Output() imagesToDeleteChange = new EventEmitter<string[]>();

  @ViewChild('subtaskEdit') subtaskEdit: SubtaskEditComponent;
  @ViewChild('metricSelection') metricSelection: SubtaskEditMetricSelectionComponent;

  view$ = new BehaviorSubject<'subtask' | 'metric' | 'newTracker'>('subtask');

  storedSubtask: Subtask;
  TaskDialogPanels = TaskDialogPanels;
  subtaskIndex: number;
  imagesArray: string[] = [];

  constructor(private tasksService: TasksService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subtaskFormArray && this.subtaskFormArray) {
      this.subtaskIndex = this.tasksService.getSubtaskEditIndex();
      this.storedSubtask = {...this.subtaskFormArray.at(this.subtaskIndex).value};
      this.imagesArray = this.tasksService.getImgArrayFromHTMLString(
        this.subtaskFormArray.at(this.subtaskIndex).value?.body || ''
      );
    }
  }

  onCancel() {
    this.removeImages(true);

    if (this.tasksService.getEditingSubtask()) {
      this.subtaskFormArray.at(this.subtaskIndex).setValue(this.storedSubtask);
    } else {
      this.subtaskFormArray.removeAt(this.subtaskIndex);
    }
    this.panelChange.emit(TaskDialogPanels.Default);
  }

  onOk() {
    this.removeImages(false);
    this.panelChange.emit(TaskDialogPanels.Default);
  }

  onImageAdded(image: string) {
    this.imagesArray.push(image);
  }

  removeImages(cancel: boolean) {
    let subtaskBody: string;
    if (cancel) {
      subtaskBody = this.storedSubtask?.body || '';
    } else {
      subtaskBody = this.subtaskFormArray.at(this.subtaskIndex).value?.body || '';
    }
    const currentImageArray = this.tasksService.getImgArrayFromHTMLString(subtaskBody);
    const imagesToDelete = this.imagesArray.filter(img => !currentImageArray.includes(img));
    this.imagesToDeleteChange.emit(imagesToDelete);
  }

  onMetricViewBack(initialMetricsState: {metricId: string; readOnly: boolean}[]) {
    // Restore initial state to the form
    this.subtaskFormArray.at(this.subtaskIndex).patchValue({
      metricIds: initialMetricsState.map(m => ({
        metricId: m.metricId,
        readOnly: m.readOnly,
      })),
    });
    this.view$.next('subtask');
  }

  onMetricViewNext(metricIds: {metricId: string; readOnly: boolean}[]) {
    // Save array of selected metric IDs to the form
    this.subtaskFormArray.at(this.subtaskIndex).patchValue({
      metricIds,
    });
    this.view$.next('subtask');
  }

  onCloseNewTrackerInDialog(metricIds: {metricId: string; readOnly: boolean}[], trackerId?: string) {
    if (trackerId && metricIds) {
      this.subtaskFormArray.at(this.subtaskIndex).patchValue({
        trackerId,
        metricIds,
      });
    }
    this.view$.next('subtask');
  }
}
