@if (
  {projectsMap: projectsMap$ | async, projects: projects$ | async, manualFormValid: manualFormValid$ | async};
  as state
) {
  <!-- --------------------------------FORM VIEW-------------------------------- -->

  @if (view === 'form') {
    <div class="form">
      <header>
        <button class="token-button" (click)="view = 'icon'">
          <oph-icon [name]="'tokens/' + form.get('icon').value" size="27"></oph-icon>
          <img src="assets/img/icons/chevron-down-brown.svg" />
        </button>
        <oph-input-inside-label
          label="Token name"
          inputType="text"
          [defaultFocus]="!preventNameInputFocus"
          [value]="nameControl.value"
          [inputStyle]="{fontSize: '16px'}"
          [invalid]="nameControl.touched && nameControl.invalid"
          fontSize="20"
          (valueChange)="onTokenNameChange($event)"
        ></oph-input-inside-label>
      </header>

      <div class="main-content">
        <oph-label-content label="Choose the project">
          <project-selector
            [selectedProject]="form.get('projectId').value ? state.projectsMap?.[form.get('projectId').value] : null"
            [loading]="state.loading?.projectAction"
            [hideActionButton]="true"
          >
            <ng-container class="project-selector">
              @for (project of state.projects; track $index) {
                <project-selector-menu-project-button
                  [project]="project"
                  [isSelected]="project?.label === state.selectedProject?.label"
                  (buttonClick)="onSelectProject(project)"
                ></project-selector-menu-project-button>
              }
            </ng-container>
          </project-selector>
        </oph-label-content>

        <div class="row" [class.disabled]="!form.get('projectId').value">
          <oph-label-content class="grow" label="Type">
            <oph-button-group
              [options]="viewOptions"
              [selectedOptionName]="form.get('type').value"
              [style]="{height: '36px', background: 'var(--light)'}"
              (optionClick)="onTypeChange($event)"
            ></oph-button-group>
          </oph-label-content>

          <oph-label-content label="Due by">
            <task-dialog-schedule-button
              (buttonClick)="view = 'date'"
              [buttonStyle]="{paddingLeft: '30px', width: '183px'}"
              >{{
                (form.get('expirationDate').value | convertUtcToTimezone: 'DD MMM YYYY') || 'Select date'
              }}</task-dialog-schedule-button
            >
          </oph-label-content>
        </div>

        <!-- ----ROUTINE TASK--- -->
        @if (form.get('type').value === 'routineTask') {
          <div [class.disabled]="!form.get('projectId').value">
            @if (form.get('taskId').value) {
              <div class="selected-task">
                <span>{{ selectedTaskName }}</span>
                <oph-x-button (buttonClick)="onRemoveTask()"></oph-x-button>
              </div>
            } @else {
              <oph-autocomplete
                placeholder="Connect a repeating task"
                [options]="projectTaskOptions"
                [style]="{height: '44px'}"
                noOptionsText="This project has no repeating tasks"
                optionIcon="repeat-green"
                (optionChange)="onTask($event)"
                (ophAutocompleteNewClick)="onNewTracker()"
              ></oph-autocomplete>
            }

            <div class="after-container">
              After
              <oph-input-orange
                [control]="completionCountContol"
                [inputStyle]="{
                  borderRadoius: '10px',
                  background: 'var(--light)',
                  fontSize: '16px',
                  height: '36px',
                  width: '70px'
                }"
                type="number"
                [showError]="completionCountContol.touched && completionCountContol.invalid"
              ></oph-input-orange>
              completions.
            </div>
          </div>
        }

        <!-- ----TRACKER--- -->
        @if (form.get('type').value === 'tracker') {
          <task-trigger-selection
            class="mb-10"
            [triggerFormGroup]="form.get('tracker')"
            hideXButton="true"
            [ophAutocompleteStyle]="{height: '44px'}"
            [ophAutocompleteNewButtonLoading]="addTrackerLoading"
            (removeItem)="onClearTracker()"
            (ophAutocompleteNewClick)="view = 'newTracker'"
          ></task-trigger-selection>
        }
      </div>
    </div>

    <div class="button-container">
      @if (data?.token) {
        <list-view-menu
          [options]="['duplicate', 'delete']"
          (action)="onMenuAction($event)"
          class="desktop"
        ></list-view-menu>
      }
      <oph-button
        color="gray"
        appearance="outline"
        [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
        (click)="onCancel()"
        >Cancel</oph-button
      >
      <oph-button
        color="green"
        [style]="{'border-radius': '8px'}"
        [loading]="loadingSave"
        [disabled]="loadingSave || !state.manualFormValid"
        (click)="onSave()"
        >Save</oph-button
      >
    </div>
  }

  <!-- --------------------------------ICON VIEW-------------------------------- -->
  @if (view === 'icon') {
    <div class="icons">
      <oph-x-button class="icon-x-button" (buttonClick)="view = 'form'"></oph-x-button>
      @for (iconGroup of tokenIconsArray; track $index) {
        <div class="icon-group">
          @for (icon of iconGroup; track $index) {
            <button
              class="icon-button"
              (mouseup)="onIcon(icon)"
              [class.selected-icon]="icon === form.get('icon').value"
            >
              <oph-icon [name]="'tokens/' + icon" [size]="iconSize"></oph-icon>
            </button>
          }
        </div>
      }
    </div>
  }

  <!-- --------------------------------DATE VIEW-------------------------------- -->
  @if (view === 'date') {
    <div class="date-container">
      <oph-x-button (buttonClick)="view = 'form'"></oph-x-button>
      <div class="calendar-container">
        <task-calendar
          [control]="form.get('expirationDate')"
          (dateChange)="onDateChange()"
          hideBorder="true"
        ></task-calendar>
      </div>
    </div>
  }

  <!-- --------------------------------NEW TRACKER VIEW-------------------------------- -->
  @if (view === 'newTracker') {
    <new-tracker-in-dialog
      [projectId]="form.get('projectId').value"
      [hideMetricCheckboxes]="true"
      (closeNewTrackerInDialog)="onCloseNewTrackerInDialog($event?.trackerId)"
    ></new-tracker-in-dialog>
  }
}
