import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, debounceTime, Observable, Subscription, take, withLatestFrom} from 'rxjs';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {
  AddMetricToTrackerAction,
  CreateNewTrackerAction,
  DeleteTrackerAction,
  DeleteTrackerMetricAction,
  GetAllTrackersAction,
  UpdateTrackerAction,
  UpdateTrackerMetricAction,
} from 'src/app/core/store/trackers/trackers.action';
import {selectSingleTracker} from 'src/app/core/store/trackers/trackers.selector';
import {MessageService} from 'src/app/services/message.service';
import {TrackerDetailMetricComponent} from 'src/app/trackers/tracker-detail/metrics/metric/tracker-detail-metric.component';
import {SharedCurrentSkedModule} from '../../current-sked/shared-current-sked.module';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';
import {OphCheckboxComponent} from '../../design/oph-checkbox/oph-checkbox.component';
import {OphIconModule} from '../../design/oph-icon/oph-icon.module';
import {OphInputOrangeComponent} from '../../design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphLoadingModule} from '../../design/oph-loading/oph-loading.module';
import {TrackersService} from '../../services/trackers.service';

@Component({
  selector: 'new-tracker-in-dialog',
  standalone: true,
  imports: [
    OphInputOrangeComponent,
    MatIconModule,
    OphButtonModule,
    TrackerDetailMetricComponent,
    SharedCurrentSkedModule,
    OphCheckboxComponent,
    OphIconModule,
    DragDropModule,
    OphLoadingModule,
    AsyncPipe,
  ],
  templateUrl: './new-tracker-in-dialog.component.html',
  styleUrl: './new-tracker-in-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTrackerInDialogComponent implements OnInit, OnDestroy {
  @Input() metricView: boolean;
  @Input() projectId: string;
  @Input() hideMetricCheckboxes: boolean;

  @Output() metricViewChange = new EventEmitter<boolean>();
  @Output() metricClick = new EventEmitter<TrackerMetric>();
  @Output() closeNewTrackerInDialog = new EventEmitter<{
    trackerId?: string;
    metricIds?: {metricId: string; readOnly: boolean}[];
  }>();

  private currentTrackerSubject$ = new BehaviorSubject<Tracker>(null);
  currentTracker$: Observable<Tracker> = this.currentTrackerSubject$.asObservable();
  loadingNewTracker$ = new BehaviorSubject<boolean>(false);

  trackerForm: FormGroup;
  metricForm: FormGroup;
  metricEditValid = false;
  formSubscription: Subscription;
  metricBeingEdited: TrackerMetric;
  loadingMetricDone = false;
  loadingMetricDelete = false;
  metricsCheckedMap: {[key: string]: boolean} = {};
  metricsReadOnlyMap: {[key: string]: boolean} = {};

  trackerSubscription: Subscription;
  trackerFormSubscription: Subscription;

  constructor(
    private store$: Store,
    private messageService: MessageService,
    private fb: FormBuilder,
    private trackersService: TrackersService
  ) {}

  ngOnInit() {
    this.trackerSubscription = this.store$.pipe(select(selectSingleTracker)).subscribe(tracker => {
      this.currentTrackerSubject$.next(tracker);
    });
    this.createNewTracker();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.trackerSubscription) {
      this.trackerSubscription.unsubscribe();
    }
    if (this.trackerFormSubscription) {
      this.trackerFormSubscription.unsubscribe();
    }
  }

  subscribeToFormChanges(): Subscription {
    return this.trackerForm.valueChanges
      .pipe(debounceTime(400), withLatestFrom(this.currentTrackerSubject$))
      .subscribe(([form, tracker]) => {
        if (!tracker || !form?.name) {
          return;
        }
        this.store$.dispatch(new UpdateTrackerAction({tracker: {...form, _id: tracker._id}}));
      });
  }

  createNewTracker() {
    this.loadingNewTracker$.next(true);
    this.store$.dispatch(
      new CreateNewTrackerAction({
        onSuccess: tracker => this.onCreateTrackerSuccess(tracker),
        onFailure: err => this.onCreateTrackerFailure(err),
      })
    );
  }

  // This adds the project id to the tracker after it's created
  onCreateTrackerSuccess(tracker: Tracker) {
    this.currentTracker$.pipe(take(1)).subscribe(currentTracker => {
      if (tracker?._id && this.projectId) {
        this.store$.dispatch(
          new UpdateTrackerAction({
            tracker: {...currentTracker, _id: tracker._id, projectIds: [this.projectId]},
            onSuccess: (tracker: Tracker) => this.onUpdateTrackerSuccess(tracker),
            onFailure: (err: Error) => this.onUpdateTrackerFailure(err),
          })
        );
      } else {
        this.loadingNewTracker$.next(false);
      }
    });
  }

  onCreateTrackerFailure(err: Error) {
    this.loadingNewTracker$.next(false);
    this.messageService.add(err.message || 'There was a problem creating the tracker');
  }

  onUpdateTrackerSuccess(tracker: Tracker) {
    this.trackerForm = this.fb.group({
      name: [tracker.name],
      projectIds: [this.projectId],
      _id: [tracker._id],
    });

    this.trackerFormSubscription = this.subscribeToFormChanges();

    this.loadingNewTracker$.next(false);
  }

  onUpdateTrackerFailure(err: Error) {
    this.loadingNewTracker$.next(false);
    this.messageService.add(err.message || 'There was a problem updating the tracker');
  }

  onTrackerCancel() {
    this.store$.dispatch(
      new DeleteTrackerAction({
        trackerId: this.trackerForm.get('_id').value,
      })
    );
    this.trackerForm.reset();
    this.closeNewTrackerInDialog.emit();
  }

  onTrackerDone() {
    const metricIds = Object.entries(this.metricsCheckedMap)
      .filter(([metricId]) => this.metricsCheckedMap[metricId])
      .map(([metricId]) => ({
        metricId,
        readOnly: this.metricsReadOnlyMap[metricId] || false,
      }));

    this.closeNewTrackerInDialog.emit({
      trackerId: this.trackerForm.get('_id').value,
      metricIds,
    });
    this.trackerForm.reset();
  }

  onAddMetric() {
    this.metricForm = this.trackersService.getMetricsFormGroup({} as TrackerMetric);
    this.handleFormSubscription();
    this.metricBeingEdited = null;
    this.metricView = true;
  }

  handleFormSubscription() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    this.formSubscription = this.metricForm.valueChanges.subscribe(form => {
      this.metricEditValid = this.trackersService.isMetricFormValid(form);
    });
  }

  onMetricEditBack() {
    this.metricForm = this.trackersService.getMetricsFormGroup({} as TrackerMetric);
    this.metricBeingEdited = null;
    this.metricView = false;
    this.handleFormSubscription();
    this.metricEditValid = this.trackersService.isMetricFormValid(this.metricForm.value);
  }

  onMetricEditDone() {
    this.loadingMetricDone = true;
    const metricDto = this.trackersService.formatMetricDto(this.metricForm);

    if (this.metricBeingEdited) {
      metricDto._id = this.metricBeingEdited._id;
      this.updateMetric(metricDto);
    } else {
      this.addNewMetric(metricDto);
    }
  }

  addNewMetric(metric: TrackerMetric) {
    this.store$.dispatch(
      new AddMetricToTrackerAction({
        trackerId: this.trackerForm.get('_id').value,
        metric,
        onSuccess: (tracker: Tracker) => this.onSuccess(tracker),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  updateMetric(metric: TrackerMetric) {
    this.store$.dispatch(
      new UpdateTrackerMetricAction({
        trackerId: this.trackerForm.get('_id').value,
        metric,
        onSuccess: (tracker: Tracker) => this.onSuccess(tracker),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  getTrackers(metricDeleted?: boolean) {
    this.store$.dispatch(new GetAllTrackersAction({params: {projectId: this.projectId, pageSize: 1000}}));
  }

  onSuccess(tracker: Tracker) {
    this.getTrackers();

    // Check all the metrics checkboxes and set default readOnly state
    if (tracker?.metrics) {
      for (const metric of tracker.metrics) {
        if (!(metric._id in this.metricsCheckedMap)) {
          this.metricsCheckedMap[metric._id] = true;
        }
        if (!(metric._id in this.metricsReadOnlyMap)) {
          this.metricsReadOnlyMap[metric._id] = false; // default to false
        }
      }
    }

    this.metricView = false;
    this.loadingMetricDone = false;
    this.metricForm.reset();
  }

  onFailure(err: Error) {
    this.loadingMetricDone = false;
    this.messageService.add(err.message || 'There was a problem adding the metric.');
  }

  drop(event: CdkDragDrop<string[]>) {
    const currentTracker = this.currentTrackerSubject$.getValue();
    if (!currentTracker) return;

    const metrics = [...currentTracker.metrics];
    moveItemInArray(metrics, event.previousIndex, event.currentIndex);

    // Update the UI immediately
    this.currentTrackerSubject$.next({
      ...currentTracker,
      metrics: metrics,
    });

    // Then dispatch the store action
    this.store$.dispatch(
      new UpdateTrackerAction({
        tracker: {
          ...currentTracker,
          metrics: metrics,
        },
        onSuccess: (tracker: Tracker) => this.getTrackers(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onMetricClick(metric: TrackerMetric) {
    this.metricEditValid = true;
    this.handleFormSubscription();

    this.metricForm = this.trackersService.getMetricsFormGroup(metric);

    this.metricView = true;
    this.metricBeingEdited = metric;
  }

  onMetricCheckChange(metricId: string, checked: boolean) {
    const updatedMap = {...this.metricsCheckedMap, [metricId]: checked};
    this.metricsCheckedMap = updatedMap;
  }

  onMetricReadOnlyChange(metricId: string, readOnly: boolean) {
    this.metricsReadOnlyMap[metricId] = readOnly;
  }

  onMetricDelete() {
    this.loadingMetricDelete = true;
    this.store$.dispatch(
      new DeleteTrackerMetricAction({
        trackerId: this.trackerForm.get('_id').value,
        metricId: this.metricBeingEdited._id,
        onSuccess: (tracker: Tracker) => this.onDeleteMetricSuccess(tracker),
        onFailure: err => this.onDeleteMetricFailure(err),
      })
    );
  }

  onDeleteMetricSuccess(tracker: Tracker) {
    this.getTrackers();
    // Wait for the tracker update to complete
    this.currentTracker$.pipe(take(1)).subscribe(() => {
      this.loadingMetricDelete = false;
      this.metricForm.reset();
      this.metricView = false;
    });
  }

  onDeleteMetricFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem deleting the metric.');
  }
}
