import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {QuillEditorComponent} from 'ngx-quill';
import Quill from 'quill';
import ImageCompress from 'quill-image-compress';
import {BehaviorSubject, catchError, lastValueFrom, map, Observable, take} from 'rxjs';
import {IMAGE_UPLOAD_MAX_SIZE} from './../../../../app.constants';
//This is being saved in case it gets added in later
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
// import './image-container-blot';
import {select, Store} from '@ngrx/store';
import {ImageHandler, Options} from 'ngx-quill-upload';
import {ALLOWED_IMAGE_TYPES, ALLOWED_IMAGE_TYPES_WARNING} from 'src/app/app.constants';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {User} from 'src/app/core/model/user';
import {selectActiveUser} from 'src/app/core/store/active-user/active-user.selector';
import {selectTrackers} from 'src/app/core/store/trackers/trackers.selector';
import {MessageService} from 'src/app/services/message.service';
import {OphAutocompleteOption} from 'src/app/shared/design/oph-autocomplete/oph-autocomplete.component';
import {environment} from 'src/environments/environment';
import * as uuid from 'uuid';
Quill.register('modules/imageHandler', ImageHandler);

//this clears stock icons. custom icons are set in scss file
const icons = Quill.import('ui/icons');
icons['header']['1'] = '<img src="">';
icons['header']['2'] = '<img src="">';
icons['bold'] = '<img src="">';
icons['italic'] = '<img src="">';
icons['underline'] = '<img src="">';
icons['list'] = '<img src="">';
icons['link'] = '<img src="">';
icons['image'] = '<img src="">';
icons['clean'] = '<img src="">';

@Component({
  selector: 'subtask-edit',
  templateUrl: './subtask-edit.component.html',
  styleUrls: ['./subtask-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtaskEditComponent implements OnChanges {
  @Input() controls: FormControl;
  @Input() taskDialog: boolean;
  @Input() fromTemplate: boolean;

  @Output() imageAdded = new EventEmitter<string>();
  @Output() connectTrackerClicked = new EventEmitter<void>();
  @Output() metricViewBack = new EventEmitter<string[]>();
  @Output() metricViewNext = new EventEmitter<{[key: string]: boolean}>();
  @Output() newTracker = new EventEmitter<void>();

  @ViewChild('editorContainer') editorContainer: ElementRef;
  @ViewChild('captionInput') captionInput: ElementRef;
  @ViewChild('inputContainer') inputContainer: ElementRef;

  user$: Observable<User> = this.store$.pipe(select(selectActiveUser));
  trackers$: Observable<Tracker[]> = this.store$.pipe(select(selectTrackers));
  inputContainerStyle$ = new BehaviorSubject<Object>({});
  selectedTracker: Tracker | null = null;
  autocompleteOptions$: Observable<OphAutocompleteOption[]>;
  availableMetrics$ = new BehaviorSubject<TrackerMetric[]>([]);
  http = inject(HttpClient);
  quill: any;
  quillEditor: any;
  parentDiv: HTMLElement;
  body = new FormControl();
  editorText: any;
  maxFileSize = IMAGE_UPLOAD_MAX_SIZE;
  currentImgArr: string[];
  allowedImageTypes = ALLOWED_IMAGE_TYPES;
  editorContainerHeight: string;
  initialMetrics: string[];

  metricsCheckedMap: {[key: string]: boolean} = {};

  quillConfig = {
    //This might get added in later
    // imageResize: {modules: ['Resize', 'DisplaySize']},
    imageCompress: {
      //default values
      quality: 0.9,
      maxWidth: 400,
      maxHeight: 1000,
      imageType: 'image/jpeg',
      debug: false,
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
    imageHandler: {
      upload: (file: any): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          if (!this.allowedImageTypes.includes(file.type)) {
            this.messageService.add(ALLOWED_IMAGE_TYPES_WARNING);
            return reject('Unsupported type');
          }
          if (file.size > this.maxFileSize * 1024 * 1024) {
            this.messageService.add(`Image size must be below ${this.maxFileSize}MB`);
            return reject('Size too large');
          }
          const uploadData = new FormData();
          // Makes the file name a unique id to avoid overwriting in s3 bucket if images have same name
          this.user$.pipe(take(1)).subscribe(user => {
            const fileName = encodeURIComponent(`${user._id}/${uuid.v4()}_${file.name.replaceAll(' ', '_')}`);
            uploadData.append('file', file, fileName);
          });

          try {
            const result: any = await lastValueFrom(
              this.http.post(`${environment.apiUrl}/imageUpload`, uploadData).pipe(
                catchError(error => {
                  reject('Upload failed');
                  throw error;
                })
              )
            );
            this.imageAdded.emit(result);
            resolve(result);
          } catch (error) {
            reject('Upload failed');
          }
        });
      },

      fetch: (url: string, callback: Function) => {
        this.http.get(url).subscribe(
          (res: any) => {
            callback(res.url);
          },
          (error: any) => {
            console.error('Error fetching image:', error);
            callback('');
          }
        );
      },
      accepts: ['png', 'jpg', 'jpeg', 'gif'],
    } as Options,
  };

  quillStyles = {
    borderRight: '1px solid #E5E3DC',
    borderLeft: '1px solid #E5E3DC',
    borderBottom: '1px solid var(--border)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    fontFamily: 'Asap',
    fontSize: '16px',
    width: '100%',
    // paddingBottom: '20px',
    maxHeight: '90%',
  };

  constructor(
    private renderer: Renderer2,
    private messageService: MessageService,
    private store$: Store
  ) {
    // Transform trackers into autocomplete options
    this.autocompleteOptions$ = this.trackers$.pipe(
      map(trackers =>
        trackers.map(tracker => ({
          name: tracker.name,
          _id: tracker._id,
        }))
      )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskDialog) {
      this.editorContainerHeight = this.taskDialog ? '300px' : '461px';
    }
    if (changes.controls && this.controls && !this.fromTemplate) {
      this.initializeMetrics();
    }
  }

  initializeMetrics() {
    // Create a deep copy of the metrics array
    this.initialMetrics = this.controls.value.metricIds.map(metric => ({...metric}));
    this.metricsCheckedMap = {};
    this.controls.value.metricIds.forEach(metric => {
      this.metricsCheckedMap[metric.metricId] = true;
    });

    if (this.controls.value.trackerId) {
      this.trackers$.pipe(take(1)).subscribe(trackers => {
        const tracker = trackers.find(t => t._id === this.controls.value.trackerId);
        if (tracker) {
          this.selectedTracker = tracker;
          const metrics = tracker.metrics.filter(metric => metric.updateable);
          this.availableMetrics$.next(metrics);
        } else {
          this.messageService.add('Tracker not found');
        }
      });
    }
  }

  onEditorCreated(editorInstance: QuillEditorComponent) {
    this.quill = editorInstance;
    this.quillEditor = (editorInstance as any)?.editor;
  }

  onQuillClick(event: any) {
    return;
    if ((this.parentDiv && this.parentDiv.id === event.target.parentElement.id) || event.target.nodeName === 'INPUT') {
      return;
    }
    if (this.parentDiv && this.parentDiv.id !== event.target.parentElement.id) {
      this.renderer.removeChild(this.parentDiv, this.inputContainer.nativeElement);
      this.parentDiv = null;
    }
    if (event.target && event.target.nodeName === 'IMG') {
      const parentDiv: HTMLElement = event.target.parentNode as HTMLElement;
      this.parentDiv = parentDiv;
      const existingSpan = parentDiv.querySelector('span');
      this.renderer.appendChild(parentDiv, this.inputContainer.nativeElement);
      if (existingSpan) {
        const spanValue = existingSpan.textContent || existingSpan.innerText;
        this.captionInput.nativeElement.value = spanValue;
      } else {
        this.captionInput.nativeElement.value = '';
      }
      this.inputContainerStyle$.next({
        left: '10px',
        top: '10px',
        visibility: 'visible',
      });
      setTimeout(() => {
        this.captionInput.nativeElement.focus();
      }, 0);
    }
  }

  onCaptionSave() {
    return;
    if (!this.parentDiv) {
      // this is to prevent enter key triggering this function
      return;
    }
    const existingSpan = this.parentDiv.querySelector('span');
    if (existingSpan) {
      existingSpan.textContent = this.captionInput.nativeElement.value;
    } else {
      const span = document.createElement('span');
      span.classList.add('quill-caption');
      span.textContent = this.captionInput.nativeElement.value;
      this.renderer.appendChild(this.parentDiv, span);
    }
    this.renderer.removeChild(this.parentDiv, this.inputContainer.nativeElement);
    this.parentDiv = null;
  }

  onTracker(option: OphAutocompleteOption) {
    this.trackers$
      .pipe(
        take(1),
        map(trackers => trackers.find(t => t._id === option._id))
      )
      .subscribe(tracker => {
        if (tracker) {
          this.controls.patchValue({trackerId: tracker._id});
          this.selectedTracker = tracker;
          const metrics = tracker.metrics.filter(metric => metric.updateable);
          this.availableMetrics$.next(metrics);
          // Initialize metricsCheckedMap for new tracker
          this.metricsCheckedMap = {};
          metrics.forEach(metric => {
            this.metricsCheckedMap[metric._id] = false;
          });
        }
      });
  }

  onConnectTrackerClick() {
    this.connectTrackerClicked.emit();
  }

  onBack() {
    this.metricViewBack.emit(this.initialMetrics);
    this.metricsCheckedMap = {};
  }

  onNext() {
    this.metricViewNext.emit({...this.metricsCheckedMap});
  }

  onRemoveTracker() {
    this.controls.patchValue({trackerId: null, metricIds: []});
    this.selectedTracker = null;
    this.availableMetrics$.next([]);
  }
}
