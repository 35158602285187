<header>
  <span>WARNING</span>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<p>Oops! You haven't added any projects to this tracker!</p>

<footer>
  <oph-button
    appearance="outline"
    color="gray"
    (buttonClick)="onClose()"
    [style]="{height: '36px', 'border-radius': '6px', padding: '0 16px'}"
    >Go Back</oph-button
  >
  <oph-button color="red" (click)="onClose(true)" [style]="{height: '36px', 'border-radius': '6px', padding: '0 16px'}"
    >Proceed Anyway</oph-button
  >
</footer>
