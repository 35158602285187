<div class="back-button-container">
  <button class="back-button" (click)="back.emit()">
    <img src="assets/img/icons/chevron-left-brown-2.svg" />
    BACK
  </button>
</div>

<div class="subtask-edit-container">
  @if (view === 'subtask') {
    <subtask-edit
      [controls]="formArray.at(subtaskIndex)"
      [taskDialog]="false"
      (imageAdded)="imageAdded.emit($event)"
      (connectTrackerClicked)="onConnectTracker()"
      (newTracker)="setView('newTracker')"
    ></subtask-edit>
  }
  @if (view === 'metric') {
    <subtask-edit-metric-selection
      #metricSelection
      [subtaskFormControl]="formArray.at(subtaskIndex)"
      (back)="onMetricViewBack($event)"
      (next)="onMetricViewNext($event)"
    ></subtask-edit-metric-selection>
  }
  @if (view === 'newTracker') {
    <new-tracker-in-dialog
      [projectId]="projectId"
      (closeNewTrackerInDialog)="onCloseNewTrackerInDialog($event?.metricIds, $event?.trackerId)"
    ></new-tracker-in-dialog>
  }
</div>
