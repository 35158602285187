@if (
  {
    activePanelIndex: activePanelIndex$ | async,
    activePanel: activePanel$ | async,
    activePanelName: activePanelName$ | async,
    panelArray: panelArray$ | async,
    confirmation: confirmation$ | async,
    hideHeaderAndFooter: hideHeaderAndFooter$ | async,
    hideHeaderAndBorder: hideHeaderAndBorder$ | async,
    nextValid: nextValid$ | async,
    subtaskEditView: subtaskEditView$ | async
  };
  as state
) {
  @if (state.activePanel) {
    <header [class.no-display]="state.confirmation || state.hideHeaderAndFooter || state.hideHeaderAndBorder">
      <span>Create a task</span>
      <button (click)="onCancel()" class="x-button">
        <img class="x-gray-icon" src="assets/img/icons/x-gray.svg" />
        <img class="x-black-icon" src="assets/img/icons/x-orange.svg" />
      </button>
    </header>
    <div class="body" [class.subtask-edit-body]="state.hideHeaderAndBorder">
      @if (state.confirmation) {
        <task-wizard-dialog-panel-item [title]="confirmationMessage">
          <div class="confirmation-button-container">
            <task-wizard-dialog-check-button width="300px" class="mb-10" (click)="onClose()"
              >Yes</task-wizard-dialog-check-button
            >
            <task-wizard-dialog-check-button width="300px" (click)="confirmation$.next(false)"
              >No</task-wizard-dialog-check-button
            >
          </div>
        </task-wizard-dialog-panel-item>
      }
      <!-- -----------------------PANELS---------------------- -->
      @if (!state.confirmation) {
        <task-wizard-dialog-panel-item
          [title]="state.hideHeaderAndFooter ? '' : state.activePanel.title"
          [subtaskEdit]="state.hideHeaderAndBorder"
          [noTitleMarginBottom]="state.activePanelName === TaskWizardPanels.Completion"
        >
          @switch (state.activePanelName) {
            @case (TaskWizardPanels.TaskOrEvent) {
              <twd-task-or-event [control]="eventControls"></twd-task-or-event>
            }
            @case (TaskWizardPanels.BasicInfo) {
              <twd-basic-info [form]="form" (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"></twd-basic-info>
            }
            @case (TaskWizardPanels.EventDate) {
              <twd-event-date [control]="scheduleOnDateControl"></twd-event-date>
            }
            @case (TaskWizardPanels.EventTime) {
              <twd-event-time
                [scheduleControls]="scheduleControls"
                (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"
              ></twd-event-time>
            }
            @case (TaskWizardPanels.TaskDate) {
              <twd-task-date [scheduleOnDateControl]="scheduleOnDateControl"></twd-task-date>
            }
            @case (TaskWizardPanels.TaskTrigger) {
              <twd-trigger
                [form]="form"
                [project]="project"
                [scheduleControls]="scheduleControls"
                (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"
              ></twd-trigger>
            }
            @case (TaskWizardPanels.EventTrigger) {
              <twd-trigger [form]="form" [project]="project" [scheduleControls]="scheduleControls"></twd-trigger>
            }
            @case (TaskWizardPanels.TaskDueBy) {
              <twd-task-due-by [scheduleControls]="scheduleControls"></twd-task-due-by>
            }
            @case (TaskWizardPanels.TaskRepeat) {
              <twd-repeat [form]="form" (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"></twd-repeat>
            }
            @case (TaskWizardPanels.EventRepeat) {
              <twd-repeat [form]="form" (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"></twd-repeat>
            }
            @case (TaskWizardPanels.Completion) {
              <twd-completion
                [scheduleControls]="scheduleControls"
                [triggersFormArray]="triggersFormArray"
                [triggerCount]="triggerCount$ | async"
                (hideHeaderAndFooter)="onHideHeaderAndFooter($event)"
              ></twd-completion>
            }
            @case (TaskWizardPanels.SubtaskList) {
              <twd-subtasks
                [formArray]="subtaskFormArray"
                (editSubtaskChange)="onEditSubtaskChange($event)"
              ></twd-subtasks>
            }
            @case (TaskWizardPanels.SubtaskEdit) {
              <twd-subtask-edit
                #twdSubtaskEdit
                [formArray]="subtaskFormArray"
                [subtaskIndex]="subtaskIndex$ | async"
                [projectId]="project._id"
                (hideHeaderAndBorder)="onHideHeaderAndBorder($event)"
                (back)="onBack()"
                (imageAdded)="onImageAdded($event)"
              ></twd-subtask-edit>
            }
            @case (TaskWizardPanels.Finish) {
              <twd-finish
                [fromCurrentSked]="fromCurrentSked"
                (allDone)="onClose(true)"
                (createAnotherTask)="onCreateAnotherTask()"
              ></twd-finish>
            }
          }
        </task-wizard-dialog-panel-item>
      }
    </div>

    @if (state.subtaskEditView !== 'newTracker') {
      <footer [class.no-display]="state.confirmation || state.hideHeaderAndFooter || (hideFooter$ | async)">
        @if (state.activePanelIndex === 0) {
          <oph-button appearance="text" color="orange" (click)="onCloseManualEntry()">Manual entry</oph-button>
        }
        @if (state.activePanelIndex > 0) {
          <oph-button
            appearance="outline"
            color="gray"
            [style]="{color: 'var(--brown-med)', width: '100%'}"
            (click)="onBack()"
            class="back-button"
            >Back</oph-button
          >
        }
        <div class="footer-right-container">
          <oph-button appearance="text" color="brown" (click)="onCancel()" class="desktop">Cancel</oph-button>
          <oph-button
            appearance="solid"
            color="green"
            [disabled]="!state.nextValid"
            [style]="{width: '100%'}"
            [loading]="loadingSave$ | async"
            (click)="onNext()"
            class="next-button"
          >
            {{ state.activePanelName === TaskWizardPanels.SubtaskList ? 'Finish' : 'Next' }}
            <img src="assets/img/icons/chevron-right-white.svg" />
          </oph-button>
        </div>
      </footer>
    }
  }
}
