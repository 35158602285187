import {Project} from '../../model/project';
import {ProjectsAction, ProjectsActionType} from '../projects/projects.action';
import {initialProjectsState, ProjectsState} from '../projects/projects.state';

export function projectsReducer(state: ProjectsState = initialProjectsState, action: ProjectsAction): ProjectsState {
  switch (action.type) {
    case ProjectsActionType.GET_SUCCESS: {
      const projectsMap = action.payload.projects?.reduce(
        (projectsMap: Record<string, Project>, obj: Project) => ((projectsMap[obj._id] = obj), projectsMap),
        {}
      );
      return {...state, projects: action.payload.projects, projectsMap};
    }
    case ProjectsActionType.GET_PROJECT_VIEW_SUCCESS: {
      const projectViews = state.projectViews;
      projectViews[action.payload.projectView._id] = action.payload.projectView;
      return {...state, projectViews};
    }
    case ProjectsActionType.UPDATE_SUCCESS: {
      const projectsMap = {...state.projectsMap, [action.payload.project._id]: action.payload.project};
      return {...state, projectsMap};
    }
    case ProjectsActionType.UPDATE_PROJECT_VIEW_TASK_ID:
      return {...state, projectViewTaskId: action.payload.id};
    case ProjectsActionType.GET_ROUTINE_DETAIL_SUCCESS: {
      const routineDetailMap = {
        ...state.routineDetailMap,
        [action.payload.taskInstanceId]: action.payload.routineDetail,
      };
      return {...state, routineDetailMap};
    }
    case ProjectsActionType.GET_TASK_TEMPLATES_SUCCESS:
      return {...state, taskTemplates: action.payload.taskTemplates};
    case ProjectsActionType.UPDATE_ROUTINE_DETAIL_PARAMS:
      return {...state, projectViewRoutineDetailParams: action.payload.params};
    case ProjectsActionType.UPDATE_PROJECT_VIEW_TOKEN_PARAMS:
      return {...state, projectViewTokenFilterParams: action.payload.params};
    case ProjectsActionType.UPDATE_TWD_SUBTASK_VIEW:
      return {...state, twsSubtaskView: action.payload.view};
    case ProjectsActionType.UPDATE_PAGE_VIEW:
      return {...state, pageView: action.payload.pageView};
    case ProjectsActionType.CLEAR:
      return {
        projects: [],
        projectsMap: {},
        projectViews: {},
        projectViewTaskId: null,
        routineDetailMap: {},
        taskTemplates: [],
        projectViewRoutineDetailParams: null,
        projectViewTokenFilterParams: null,
        twsSubtaskView: 'subtask',
        pageView: 'view',
      };
    default:
      return state;
  }
}
