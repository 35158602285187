import {Action} from '@ngrx/store';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {TaskStatusActions} from 'src/app/current-sked/shared/task-status-actions';
import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';
import {CurrentSkedTask, SubtaskNote} from '../../model/task';
import {SkedType} from './current-sked.state';
import {HttpErrorResponse} from '@angular/common/http';
import {UpdateSubtaskMetric} from 'src/app/current-sked/task-detail/current-sked-task-detail.component';

export enum CurrentSkedActionType {
  GET = '[Current Sked] Get',
  GET_SUCCESS = '[Current Sked] Get :: Success',

  GET_ALL_SKEDS = '[Current Sked] Get All Skeds',
  GET_ALL_SKEDS_SUCCESS = '[Current Sked] Get All Skeds :: Success',

  GET_TASK = '[Current Sked] Get Task',
  GET_TASK_SUCCESS = '[Current Sked] Get Task :: Success',

  CHANGE_TASK_STATUS = '[Current Sked] Change Task Status',
  CHANGE_TASK_STATUS_SUCCESS = '[Current Sked] Change Task Status :: Success',

  CHANGE_SUBTASK_STATUS = '[Current Sked] Change Subtask Status',
  CHANGE_SUBTASK_STATUS_SUCCESS = '[Current Sked] Change Subtask Status :: Success',

  UPDATE_SUBTASK_METRICS = '[Current Sked] Update Subtask Metrics',
  UPDATE_SUBTASK_METRICS_SUCCESS = '[Current Sked] Update Subtask Metrics :: Success',

  CHANGE_THEME = '[Current Sked] Change Theme',

  UPDATE_ALL_SKEDS = '[Current Sked] Update All Skeds',
  UPDATE_ACTIVE_TAB = '[Current Sked] Update Active Tab',
  UPDATE_SELECTED_SKED_TYPE = '[Current Sked] Update Selected Sked Type',
  UPDATE_HEADER_LOADING = '[Current Sked] Update Header Loading',

  UPDATE_CURRENT_SKED = '[Current Sked] Update Current Sked',
  UPDATE_CURRENT_SKED_SUCCESS = '[Current Sked] Update Current Sked :: Success',

  UPDATE_VALUE_CHOSEN = '[Current Sked] Update Current Sked Value Chosen',
  CLEAR_VALUE_CHOSEN = '[Current Sked] Clear Current Sked Value Chosen',

  ADD_SUBTASK_NOTE = '[Current Sked] Add Subtask Note',
  ADD_SUBTASK_NOTE_SUCCESS = '[Current Sked] Add Subtask Note :: Success',

  CLEAR_TASK = '[Current Sked] Clear Task',

  CLEAR = '[Current Sked] Clear',
}

export class GetCurrentSkedAction implements Action {
  public readonly type = CurrentSkedActionType.GET;

  public constructor(
    public payload: {
      skedType: SkedType;
      onSuccess?: (currentSked: Sked) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSkedsSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_SUCCESS;

  public constructor(public payload: {sked: Sked; skedType: SkedType}) {}
}

//------------------------------------------------------------

export class GetAllDaySkedsAction implements Action {
  public readonly type = CurrentSkedActionType.GET_ALL_SKEDS;

  public constructor(
    public payload: {
      onSuccess?: (skeds: Sked[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetAllDaySkedsSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_ALL_SKEDS_SUCCESS;

  public constructor(public payload: {skeds: Sked[]}) {}
}

//------------------------------------------------------------

export class GetCurrentSkedTaskAction implements Action {
  public readonly type = CurrentSkedActionType.GET_TASK;

  public constructor(
    public payload: {
      taskId: string;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetCurrentSkedTaskSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.GET_TASK_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------

export class CurrentSkedChangeTaskStatusAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_TASK_STATUS;

  public constructor(
    public payload: {
      id: string;
      actionType: TaskStatusActions;
      skedId: string;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedChangeTaskStatusSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_TASK_STATUS_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------

export class CurrentSkedChangeSubtaskStatusAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_SUBTASK_STATUS;

  public constructor(
    public payload: {
      taskId: string;
      subtaskId: string;
      complete: boolean;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedChangeSubtaskStatusSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.CHANGE_SUBTASK_STATUS_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------
export class CurrentSkedUpdateSubtaskMetricsAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SUBTASK_METRICS;

  public constructor(
    public payload: {
      trackerId: string;
      taskId: string;
      metrics: UpdateSubtaskMetric[];
      subtaskId: string;
      timestamp?: string;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CurrentSkedUpdateSubtaskMetricsSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SUBTASK_METRICS_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

//------------------------------------------------------------
export class UpdateAllSkedsAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_ALL_SKEDS;

  public constructor(public payload: {allSkeds: Sked[]}) {}
}

export class UpdateCurrentSkedActiveTabAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_ACTIVE_TAB;

  public constructor(public payload: {activeTab: CurrentSkedTabOptions}) {}
}

export class UpdateSelectedSkedTypeAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_SELECTED_SKED_TYPE;

  public constructor(public payload: {selectedSkedType: SkedType}) {}
}

export class UpdateCurrentSkedHeaderLoadingAction implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_HEADER_LOADING;

  public constructor(public payload: {headerLoading: boolean}) {}
}

export class UpdateCurrentSked implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_CURRENT_SKED;

  public constructor(public payload: {sked: Sked; onSuccess?: (sked: Sked) => void}) {}
}

export class UpdateCurrentSkedSuccess implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_CURRENT_SKED_SUCCESS;

  public constructor(public payload: {sked: Sked}) {}
}

export class UpdateCurrentSkedValueChosen implements Action {
  public readonly type = CurrentSkedActionType.UPDATE_VALUE_CHOSEN;

  public constructor(public payload: {id: string}) {} // the value will always be true, so only key is needed. key can be any id
}

export class ClearCurrentSkedValueChosen implements Action {
  public readonly type = CurrentSkedActionType.CLEAR_VALUE_CHOSEN;
}

export class AddSubtaskNoteAction implements Action {
  public readonly type = CurrentSkedActionType.ADD_SUBTASK_NOTE;

  public constructor(
    public payload: {
      subtaskNote: SubtaskNote;
      onSuccess?: (task: CurrentSkedTask) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class AddSubtaskNoteSuccessAction implements Action {
  public readonly type = CurrentSkedActionType.ADD_SUBTASK_NOTE_SUCCESS;

  public constructor(public payload: {task: CurrentSkedTask}) {}
}

export class ClearCurrentSkedTaskAction implements Action {
  public readonly type = CurrentSkedActionType.CLEAR_TASK;
}

export class ClearCurrentSkedAction implements Action {
  public readonly type = CurrentSkedActionType.CLEAR;
}

export type CurrentSkedAction =
  | GetCurrentSkedAction
  | GetSkedsSuccessAction
  | GetAllDaySkedsAction
  | GetAllDaySkedsSuccessAction
  | GetCurrentSkedTaskAction
  | GetCurrentSkedTaskSuccessAction
  | CurrentSkedChangeTaskStatusAction
  | CurrentSkedChangeTaskStatusSuccessAction
  | CurrentSkedChangeSubtaskStatusAction
  | CurrentSkedChangeSubtaskStatusSuccessAction
  | CurrentSkedUpdateSubtaskMetricsAction
  | CurrentSkedUpdateSubtaskMetricsSuccessAction
  | UpdateAllSkedsAction
  | UpdateCurrentSkedActiveTabAction
  | UpdateSelectedSkedTypeAction
  | UpdateCurrentSkedHeaderLoadingAction
  | UpdateCurrentSked
  | UpdateCurrentSkedValueChosen
  | AddSubtaskNoteAction
  | AddSubtaskNoteSuccessAction
  | ClearCurrentSkedValueChosen
  | ClearCurrentSkedTaskAction
  | ClearCurrentSkedAction;
