@if (view === 'step') {
  <h2>{{ data.step ? 'Edit' : 'Create' }} Step</h2>

  <div class="main-container">
    @if (!confirmView) {
      <oph-input-orange
        [control]="form.get('name')"
        [inputStyle]="{height: '50px', fontSize: '16px'}"
        placeholder="Step title"
      ></oph-input-orange>

      <!-- ----ACTIVATION TRACKER--- -->
      @if (data.stepIndex !== 0) {
        <div class="row" [class.disabled]="form.get('previousStepRequired').value">
          <span class="text">Step Activation</span>
          <span class="sub-text">Select the value that indicates when the step becomes available:</span>
        </div>

        <task-trigger-selection
          class="mb-10"
          [triggerFormGroup]="form.get('activationTracker')"
          [error]="trackerError"
          hideXButton="true"
          [ophAutocompleteStyle]="{height: '44px'}"
          [ophAutocompleteNewButtonLoading]="addTrackerLoading"
          (removeItem)="onClearActivationTracker()"
          (ophAutocompleteNewClick)="onNewTracker('activation')"
          (removeItem)="onClearTracker('completionTracker')"
          [class.disabled]="form.get('previousStepRequired').value"
        ></task-trigger-selection>
      }

      <!-- ----SEQUENTIAL SECTION--- -->
      @if (data.stepIndex !== 0) {
        <div class="row">
          <span class="text">Make Sequential</span>
          <span class="sub-text">Previous step must be complete before this step is available.</span>
          <oph-button-group
            class="boolean-button-group"
            [options]="booleanOptions"
            [selectedOptionName]="form.get('previousStepRequired').value ? 'yes' : 'no'"
            [style]="{width: '200px'}"
            [buttonStyle]="{padding: '0px 10px'}"
            (optionClick)="onBooleanOptionChange($event, 'previousStepRequired')"
          ></oph-button-group>
        </div>
      }

      <!-- ----COMPLETION TRACKER--- -->
      <div class="row">
        <span class="text">Step Completion</span>
        <span class="sub-text">Select the value that indicates when the step is complete:</span>
      </div>

      <task-trigger-selection
        class="mb-10"
        [triggerFormGroup]="form.get('completionTracker')"
        [error]="trackerError"
        hideXButton="true"
        [ophAutocompleteStyle]="{height: '44px'}"
        [ophAutocompleteNewButtonLoading]="addTrackerLoading"
        (removeItem)="onClearTracker('completionTracker')"
        (ophAutocompleteNewClick)="onNewTracker('completion')"
      ></task-trigger-selection>

      @if (trackerError) {
        <span class="tracker-error">Activation and completion triggers cannot be the same</span>
      }
    } @else {
      <!-- --------------CONFIRM VIEW---------------- -->
      <div class="confirm-view">
        <oph-icon name="warning-yellow" [size]="48"></oph-icon>
        This step will not autocomplete unless a tracker is selected.
      </div>
    }
  </div>

  <div class="button-container">
    @if (confirmView) {
      <oph-button
        color="gray"
        appearance="outline"
        [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
        (click)="confirmView = false"
        >Back</oph-button
      >
    } @else {
      @if (data.stepIndex !== null) {
        @if (data.project.steps.length > 1) {
          <div class="delete-button-container" [class.disabled-delete-container]="stepHasTasks">
            <oph-button
              color="red"
              appearance="outline"
              [style]="{
                'border-width': '0.5px',
                'border-radius': '8px',
                color: 'var(--brown-med)',
                'border-color': stepHasTasks ? 'var(--scrollbar)' : '',
                color: stepHasTasks ? 'var(--scrollbar)' : ''
              }"
              (click)="onDelete()"
              >Delete</oph-button
            >
            @if (stepHasTasks) {
              <mat-icon svgIcon="info"></mat-icon>
              <span>Step must be empty</span>
            }
          </div>
        }
      }
      <oph-button
        color="gray"
        appearance="outline"
        [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
        (click)="onCancel()"
        >Cancel</oph-button
      >
    }
    <oph-button
      color="green"
      [style]="{'border-radius': '8px'}"
      [loading]="loadingSave"
      [disabled]="loadingSave || !form.valid || trackerError"
      (click)="onSave()"
      >Finish</oph-button
    >
  </div>
}

@if (view === 'newTracker') {
  <new-tracker-in-dialog
    [projectId]="data.project._id"
    [hideMetricCheckboxes]="true"
    (closeNewTrackerInDialog)="onCloseNewTrackerInDialog($event?.trackerId)"
  ></new-tracker-in-dialog>
}
