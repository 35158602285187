<div class="dropdown-button-container" [matTooltip]="availableTaskActions[taskStatuses.Unclaimed]?.message">
  <button
    class="status-button"
    [style]="{backgroundColor: getTaskStatus().primeColor}"
    (click)="startTask()"
    [disabled]="loading.action || !task.authorizationObject.isAuthorized"
    [class.disabled-status-button]="!task.authorizationObject.isAuthorized"
  >
    @if (loading.action) {
      <div class="spinner-container">
        <mat-spinner class="custom-color" diameter="20"></mat-spinner>
      </div>
    } @else {
      <span>{{ getTaskStatus().label }}</span>
    }
    @if (!task.authorizationObject.isAuthorized) {
      <div class="menu-button info-button" (click)="onInfo()">
        <mat-icon class="info-icon" svgIcon="info-i"></mat-icon>
      </div>
    } @else {
      <div
        class="menu-button"
        [style]="{backgroundColor: getTaskStatus().secColor}"
        (click)="toggleDropdownMenu($event)"
      >
        <mat-icon class="arrow-icon" svgIcon="chevron-down"></mat-icon>
      </div>
    }
  </button>
  <div class="dropdown-menu" [class.open]="menuOpen" [appOutSideClick]="menuOpen" (outSideClick)="menuOpen = false">
    @for (option of statusOptions; track option) {
      <div [matTooltip]="availableTaskActions[option.status]?.message" matTooltipPosition="left">
        <div
          class="option border"
          (click)="setTaskStatus(option.action)"
          [class.disabled-menu-option]="availableTaskActions[option.status]?.disabled"
        >
          <div class="label">
            <div class="dot" [style]="{backgroundColor: option.primeColor}"></div>
            <span>{{ option.label }}</span>
          </div>
          <mat-icon class="check" [class.show]="task.status === option.status" svgIcon="check"></mat-icon>
          @if (option.status === taskStatuses.Completed && showCompleteInfoButton) {
            <button class="info-circle-button" (click)="onInfo($event, true)">
              <mat-icon class="info-icon" svgIcon="info-i"></mat-icon>
            </button>
          }
        </div>
      </div>
    }
  </div>
</div>
