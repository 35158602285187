import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SubtaskNote} from 'src/app/core/model/task';
import {trigger, transition, style, animate} from '@angular/animations';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {Store} from '@ngrx/store';
import {User} from 'src/app/core/model/user';
import {Observable} from 'rxjs';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';

@Component({
  selector: 'current-sked-task-detail-subtask-image-dialog',
  standalone: true,
  imports: [CommonModule, OphIconModule, PipesModule, OphXButtonComponent],
  templateUrl: './current-sked-task-detail-subtask-image-dialog.component.html',
  styleUrl: './current-sked-task-detail-subtask-image-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-out', style({transform: 'translateX(0)'})),
      ]),
      transition(':decrement', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-out', style({transform: 'translateX(0)'})),
      ]),
    ]),
  ],
})
export class CurrentSkedTaskDetailSubtaskImageDialogComponent {
  currentIndex = 0;
  usersMap$: Observable<Record<string, User>> = this.store$.select(selectUsersMap);

  constructor(
    @Inject(MAT_DIALOG_DATA) public note: SubtaskNote,
    private store$: Store,
    public dialogRef: MatDialogRef<CurrentSkedTaskDetailSubtaskImageDialogComponent>
  ) {}

  onPrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  onNext() {
    if (this.currentIndex < this.note.images.length - 1) {
      this.currentIndex++;
    }
  }
}
