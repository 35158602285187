import {createSelector} from '@ngrx/store';
import {Project} from '../../model/project';
import {AppState} from '../app-state';

export const selectProjectsState = (state: AppState) => state.projects;

export const selectAllProjects = createSelector(selectProjectsState, state => state.projects);

export const selectProjectsMap = createSelector(selectProjectsState, state => state.projectsMap);

export const selectProject = (id: string) =>
  createSelector(selectProjectsMap, projectsMap => projectsMap[id] || ({} as Project));

export const selectProjectViews = createSelector(selectProjectsState, state => state.projectViews);

export const selectProjectViewTaskId = createSelector(selectProjectsState, state => state.projectViewTaskId);

export const selectProjectViewRoutineDetailMap = createSelector(selectProjectsState, state => state.routineDetailMap);

export const selectTaskTemplates = createSelector(selectProjectsState, state => state.taskTemplates);

export const selectProjectViewRoutineDetailParams = createSelector(
  selectProjectsState,
  state => state.projectViewRoutineDetailParams
);

export const selectProjectViewTokenFilterParams = createSelector(
  selectProjectsState,
  state => state.projectViewTokenFilterParams
);

export const selectTwdSubtaskView = createSelector(selectProjectsState, state => state.twsSubtaskView);

export const selectProjectsPageView = createSelector(selectProjectsState, state => state.pageView);
