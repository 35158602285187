import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {TimezoneService} from 'src/app/services/timezone.service';
import {OphXButtonComponent} from '../../design/oph-x-button/oph-x-button.component';
import {OphSelectMenuComponent} from '../../design/oph-select-menu/oph-select-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';
import * as moment from 'moment-timezone';
interface DialogData {
  timestamp: string;
}

@Component({
  selector: 'oph-timestamp-dialog',
  standalone: true,
  imports: [
    OphXButtonComponent,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    DatePipe,
    OphSelectMenuComponent,
    MatIconModule,
    OphButtonModule,
  ],
  templateUrl: './oph-timestamp-dialog.component.html',
  styleUrl: './oph-timestamp-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphTimestampDialogComponent {
  readonly dialogRef = inject(MatDialogRef<OphTimestampDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);

  maxDate = moment().tz(this.timezoneService.getTimeZone()).toDate();
  minDate = moment().tz(this.timezoneService.getTimeZone()).subtract(7, 'days').toDate();

  form = new FormGroup({
    date: new FormControl<string>(''),
    hours: new FormControl<string>('12'),
    minutes: new FormControl<string>('00'),
    ampm: new FormControl<string>('AM'),
  });

  hours = Array.from({length: 12}, (_, i) => ({
    name: (i + 1).toString(),
    value: (i + 1).toString(),
  }));

  minutes = Array.from({length: 12}, (_, i) => ({
    name: (i * 5).toString().padStart(2, '0'),
    value: (i * 5).toString(),
  }));

  ampm = [
    {name: 'AM', value: 'AM'},
    {name: 'PM', value: 'PM'},
  ];

  selectButtonStyle = {width: '70px'};

  constructor(private timezoneService: TimezoneService) {
    this.setTimestamp();
  }

  setTimestamp() {
    const timestamp = this.data.timestamp;
    const dateMoment = moment(timestamp || undefined).tz(this.timezoneService.getTimeZone());
    this.setDate(dateMoment);
    this.setTime(dateMoment);
  }

  setDate(moment: moment.Moment) {
    this.form.get('date')?.setValue(moment.format('MM/DD/YYYY'));
  }

  setTime(moment: moment.Moment) {
    const hours = moment.format('h'); // 12-hour format
    const minutes = moment.format('mm');
    const ampm = moment.format('A'); // Returns 'AM' or 'PM'

    this.form.patchValue({
      hours,
      minutes,
      ampm,
    });
  }

  onSave(): void {
    const date = this.form.get('date')?.value;
    const hours = this.form.get('hours')?.value;
    const minutes = this.form.get('minutes')?.value;
    const ampm = this.form.get('ampm')?.value;

    if (!date) return;

    const timestampMoment = moment(date, 'M/D/YYYY').tz(this.timezoneService.getTimeZone());
    timestampMoment.set({
      hour: parseInt(hours) + (ampm === 'PM' && parseInt(hours) !== 12 ? 12 : 0),
      minute: parseInt(minutes),
    });

    this.dialogRef.close(timestampMoment.toISOString());
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onTimeChange(type: 'hour' | 'minute' | 'ampm', value: string) {
    if (type === 'hour') {
      this.form.patchValue({hours: value});
    } else if (type === 'minute') {
      this.form.patchValue({minutes: value.padStart(2, '0')});
    } else if (type === 'ampm') {
      this.form.patchValue({ampm: value});
    }
  }
}
