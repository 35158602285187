import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {BehaviorSubject} from 'rxjs';
import {CurrentSkedTask, Subtask} from 'src/app/core/model/task';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {SubtaskStatusChange} from '../../shared/model/status-change';
import {TaskLoadingState} from '../../shared/model/task-loading-state';
import {TaskStatusActions} from '../../shared/task-status-actions';
import {TaskStatuses} from '../../shared/task-statuses';
import {TrackerMetricTypes} from './../../../trackers/shared/tracker-metric-types';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HasUpdateableMetricsPipe} from '../shared/has-updateable-metrics.pipe';

@Component({
  selector: 'current-sked-task-detail-subtask',
  standalone: true,
  imports: [
    CommonModule,
    SharedCurrentSkedModule,
    ReactiveFormsModule,
    PipesModule,
    MatIconModule,
    MatExpansionModule,
    OphButtonModule,
    MatTooltipModule,
    HasUpdateableMetricsPipe,
  ],
  templateUrl: './current-sked-task-detail-subtask.component.html',
  styleUrl: './current-sked-task-detail-subtask.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailSubtaskComponent implements OnChanges {
  @Input() task: CurrentSkedTask;
  @Input() subtask: Subtask;
  @Input() subtaskIndex: number;
  @Input() loading: TaskLoadingState;
  @Input() ableToCompleteSubtaskIndex: number;

  @Output() subtaskClick = new EventEmitter();
  @Output() updateSubtaskStatus = new EventEmitter<boolean>();
  @Output() updateTaskStatus = new EventEmitter<TaskStatusActions>();

  @ViewChild('panel') panel: MatExpansionPanel;

  pendingSubtaskUpdateData$ = new BehaviorSubject<SubtaskStatusChange>(null);

  TrackerMetricTypes = TrackerMetricTypes;
  TaskStatuses = TaskStatuses;
  form = new FormArray([]);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subtask && this.subtask) {
      // Add form controls for each metric
      if (this.subtask.tracker?.metrics?.length) {
        this.subtask.tracker.metrics.forEach(metric => {
          this.addMetricFormItem(metric);
        });
      }
    }
  }

  addMetricFormItem(metric: TrackerMetric) {
    this.form.push(
      new FormGroup({
        _id: new FormControl(metric._id),
        value: new FormControl(metric.value || null),
      })
    );
  }

  onSubtask(event: PointerEvent): void {
    event.stopPropagation();
    this.subtaskClick.emit();
  }

  onCheckboxClick(checked: boolean): void {
    this.updateSubtaskStatus.emit(checked);
  }

  onMetricToggle(event: PointerEvent): void {
    event.stopPropagation();
  }
}
