@if (view$ | async; as view) {
  @if (subtaskFormArray.at(subtaskIndex)) {
    <div class="subtask-edit-container oph-scroll-bar" [style.height]="view === 'newTracker' ? '100%' : '90%'">
      @if (view === 'subtask') {
        <subtask-edit
          #subtaskEdit
          [controls]="subtaskFormArray.at(subtaskIndex)"
          [taskDialog]="true"
          [fromTemplate]="fromTemplate"
          (imageAdded)="onImageAdded($event)"
          (connectTrackerClicked)="view$.next('metric')"
          (newTracker)="view$.next('newTracker')"
        ></subtask-edit>
      }
      @if (view === 'metric') {
        <subtask-edit-metric-selection
          #metricSelection
          [subtaskFormControl]="subtaskFormArray.at(subtaskIndex)"
          (back)="onMetricViewBack($event)"
          (next)="onMetricViewNext($event)"
        ></subtask-edit-metric-selection>
      }
      @if (view === 'newTracker') {
        <new-tracker-in-dialog
          [projectId]="projectId"
          (closeNewTrackerInDialog)="onCloseNewTrackerInDialog($event?.metricIds, $event?.trackerId)"
        ></new-tracker-in-dialog>
      }
    </div>
  }

  @if (view !== 'newTracker') {
    <task-dialog-panel-footer
      (cancel)="onCancel()"
      (ok)="onOk()"
      (back)="metricSelection?.onBack()"
      (next)="metricSelection?.onNext()"
      [disabled]="!subtaskFormArray.at(subtaskIndex).value.title"
      [view]="view"
    ></task-dialog-panel-footer>
  }
}
