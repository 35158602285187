<oph-input
  fontSize="16"
  [control]="controls?.get('title')"
  placeholder="Subtask title*"
  height="60"
  autoFocusInput="true"
></oph-input>

<div class="editor-container" #editorContainer [style.height]="editorContainerHeight + ' !important'">
  <quill-editor
    #editor
    placeholder="Instructions"
    [modules]="quillConfig"
    [bounds]="'self'"
    [styles]="quillStyles"
    [formControl]="controls?.get('body')"
    (onEditorCreated)="onEditorCreated($event)"
    (click)="onQuillClick($event)"
    scrollingContainer="true"
    [class.task-dialog-quill-editor]="taskDialog"
    [class.task-wizard-quill-editor]="!taskDialog"
  >
    <div quill-editor-toolbar class="quill-toolbar">
      <button class="ql-header h1-icon" value="1"></button>
      <button class="ql-header h2-icon" value="2"></button>
      <button class="ql-bold bold-icon"></button>
      <button class="ql-italic italic-icon"></button>
      <button class="ql-underline underline-icon"></button>
      <button class="ql-list list-icon" value="bullet"></button>
      <button class="ql-link link-icon"></button>
      <button class="ql-image image-icon"></button>
      <button class="ql-clean clean-icon"></button>
    </div>
  </quill-editor>

  <div class="input-container" [ngStyle]="inputContainerStyle$ | async" #inputContainer>
    <input type="text" placeholder="Enter caption" #captionInput />
    <button class="input-save-button" (click)="onCaptionSave()">Save</button>
  </div>
</div>

@if (controls?.value?.trackerId) {
  <div class="tracker-container">
    <button class="tracker-name-button" (click)="onConnectTrackerClick()">
      <mat-icon svgIcon="tracker"></mat-icon>
      <span class="tracker-name truncate-text">{{ selectedTracker?.name }}</span>
    </button>
    <div class="tracker-metrics-container">
      <span>{{ availableMetrics$.value?.length || 0 }} Metrics</span>
    </div>
    <oph-x-button (click)="onRemoveTracker()"></oph-x-button>
  </div>
} @else {
  <div class="connect-tracker-button-container">
    <button
      class="connect-tracker-button"
      (click)="onConnectTrackerClick()"
      [disabled]="fromTemplate"
      [class.disabled]="fromTemplate"
    >
      <mat-icon svgIcon="tracker"></mat-icon>
      Connect tracker
    </button>
    <oph-new-button
      (buttonClick)="newTracker.emit()"
      [buttonStyle]="{
        width: '200px',
        height: '38px',
        flexShrink: '0',
        borderRadius: '8px',
        border: '1px solid var(--border)',
        background: 'transparent'
      }"
      [disabled]="fromTemplate"
      [class.disabled]="fromTemplate"
      >New Tracker</oph-new-button
    >
  </div>
}
