import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {WEBROOT} from '../app.constants';
import {LoginDto} from '../core/api/dto/login.dto';
import {SetActiveUser, SetActiveUserRole, SetActiveUserPrivileges} from '../core/store/active-user/active-user.action';
import {ClearCurrentSkedAction} from '../core/store/current-sked/current-sked.action';
import {UpdateScheduleQueryParams} from '../core/store/schedule/schedule.action';
import {ClearScheduleAction} from '../core/store/schedule/schedule.action';
import {ClearSchedulerAction} from '../core/store/scheduler/scheduler.action';
import {ClearReportingAction} from '../core/store/reporting/reporting.action';
import {ClearPrivilegesAction} from '../core/store/privileges/privileges.action';
import {ClearProgramsAction} from '../core/store/programs/programs.action';
import {ClearRolesAction} from '../core/store/roles/roles.action';
import {ClearTeamsAction} from '../core/store/teams/teams.action';
import {ClearUsersAction} from '../core/store/users/users.action';
import {ClearActionsAction} from '../core/store/actions/actions.action';
import {ClearLocationsAction} from '../core/store/locations/locations.action';
import {ClearTokensAction} from '../core/store/tokens/tokens.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router
  ) {}

  login(email: string, password: string) {
    return this.http.post<LoginDto>(`${WEBROOT}/login`, {email, password: password}).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes, remove id
          delete user._id;
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.configureSentryUserScope(user.email);
          this.loggedIn.next(true);
        }
        return user;
      })
    );
  }

  logout(preventRoute?: boolean) {
    // Clear ngrx state
    this.store.dispatch(new SetActiveUser({user: null}));
    this.store.dispatch(new SetActiveUserRole({role: null}));
    this.store.dispatch(new SetActiveUserPrivileges({privileges: null}));
    this.store.dispatch(new ClearCurrentSkedAction());
    this.store.dispatch(new UpdateScheduleQueryParams({params: null}));
    this.store.dispatch(new ClearScheduleAction());
    this.store.dispatch(new ClearSchedulerAction());
    this.store.dispatch(new ClearReportingAction());
    this.store.dispatch(new ClearActionsAction());
    this.store.dispatch(new ClearLocationsAction());
    this.store.dispatch(new ClearPrivilegesAction());
    this.store.dispatch(new ClearProgramsAction());
    this.store.dispatch(new ClearRolesAction());
    this.store.dispatch(new ClearTeamsAction());
    this.store.dispatch(new ClearUsersAction());
    this.store.dispatch(new ClearTokensAction());

    // Remove from local storage
    localStorage.removeItem('currentUser');

    // Update Sentry and logged in status
    this.configureSentryUserScope(null);
    this.loggedIn.next(false);

    if (!preventRoute) {
      this.router.navigate(['/login']);
    }
  }

  private configureSentryUserScope(username: string) {
    if (!environment.sentryDsn) {
      return;
    }

    Sentry.setUser(username ? {username} : null);
  }

  requestPasswordReset(email: string, host: string) {
    return this.http.post<string>(`${WEBROOT}/requestPasswordReset`, {email, host});
  }

  validateResetToken(token: string) {
    return this.http.post<{valid: boolean}>(`${WEBROOT}/validateResetToken`, {token});
  }

  resetPassword(token: string, password: string) {
    return this.http.post<string>(`${WEBROOT}/resetPassword`, {token, password});
  }
}
