import {Injectable} from '@angular/core';
import {CanDeactivateFn} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import {map, take, switchMap} from 'rxjs/operators';
import {TrackerDetailComponent} from './tracker-detail.component';
import {TrackerDetailProjectWarningDialogComponent} from './project-warning-dialog/tracker-detail-project-warning-dialog.component';
import {Store} from '@ngrx/store';
import {select} from '@ngrx/store';
import {selectProjectsMap} from 'src/app/core/store/projects/projects.selector';

@Injectable({
  providedIn: 'root',
})
export class TrackerDetailGuard {
  constructor(
    private dialog: MatDialog,
    private store$: Store
  ) {}

  canDeactivate: CanDeactivateFn<TrackerDetailComponent> = (component: TrackerDetailComponent): Observable<boolean> => {
    if (component.hideNoProjectsWarning) {
      return of(true);
    }

    return this.store$.pipe(
      select(selectProjectsMap),
      take(1),
      map(projectsMap => {
        const projectIds = component.form?.get('projectIds')?.value.filter(id => projectsMap[id]) || [];

        if (projectIds.length === 0) {
          return this.showWarningDialog();
        }

        return of(true);
      }),
      switchMap(result => result)
    );
  };

  private showWarningDialog(): Observable<boolean> {
    const dialog = this.dialog.open(TrackerDetailProjectWarningDialogComponent, {
      backdropClass: 'oph-backdrop',
      panelClass: 'list-views-warning-dialog',
    });

    return dialog.afterClosed().pipe(
      take(1),
      map(result => !!result)
    );
  }
}
