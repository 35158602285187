import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {TasksService} from 'src/app/shared/services/tasks.service';
import {TaskDialogPanels} from '../../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-triggers-tab',
  templateUrl: './task-dialog-triggers-tab.component.html',
  styleUrls: ['./task-dialog-triggers-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogTriggersTabComponent {
  @Input() form: FormGroup;
  @Input() validationErrors: Record<string, boolean>;
  @Input() projectId: string;
  @Output() areTriggersComplete = new EventEmitter<boolean>();
  @Output() panelChange = new EventEmitter<{panel: TaskDialogPanels; index: number}>();

  triggersComplete = [];

  constructor(private taskService: TasksService) {}

  onAddCondition() {
    this.triggerFormArray.push(this.taskService.getTriggerFormGroup());
  }

  onRemoveCondition(index: number) {
    this.triggerFormArray.removeAt(index);
    if (this.triggerFormArray.length > 0) {
      return;
    }
    if (this.scheduleTypeControl.value === 'doNotRepeat') {
      this.targetCompletionTypeControl.setValue('endOfProject');
      this.expirationTypeControl.setValue('endOfProject');
    }
  }

  onNewTracker(index: number) {
    this.panelChange.emit({panel: TaskDialogPanels.NewTracker, index});
  }

  get eventControl(): AbstractControl {
    return this.form.get('event');
  }

  get triggerFormArray(): FormArray {
    return this.form.get('triggers') as FormArray;
  }

  get scheduleControls(): AbstractControl {
    return this.form.get('schedule');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControls.get('scheduleType');
  }

  get autoResetControl(): AbstractControl {
    return this.form.get('autoReset');
  }

  get targetCompletionTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionType');
  }

  get expirationTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationType');
  }
}
