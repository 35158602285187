<header>
  <span>{{ data?.metric ? 'Edit' : 'New' }} Metric</span>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<main>
  <tracker-detail-metric
    [metricForm]="metricForm"
    [editing]="!!data?.metric"
    [trackerDetail]="true"
  ></tracker-detail-metric>
</main>

<footer>
  <oph-button appearance="outline" color="gray" [style]="{color: 'var(--brown-med)'}" (buttonClick)="onClose()"
    >Cancel</oph-button
  >
  <oph-button color="green" (buttonClick)="onAddMetric()" [disabled]="!valid" [loading]="loading$ | async"
    >Apply</oph-button
  >
</footer>
