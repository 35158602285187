import {Pipe, PipeTransform} from '@angular/core';
import {Subtask} from 'src/app/core/model/task';

@Pipe({
  name: 'hasUpdateableMetrics',
  standalone: true,
})
export class HasUpdateableMetricsPipe implements PipeTransform {
  transform(subtask: Subtask, checkReadOnly: boolean = false): boolean {
    if (checkReadOnly) {
      return subtask.tracker?.metrics?.some(metric => metric.readOnly || metric.updateable);
    }
    return subtask.tracker?.metrics?.some(metric => !metric.readOnly && metric.updateable);
  }
}
