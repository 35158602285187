<button
  class="subtask-button"
  (click)="onSubtask($event)"
  [class.disabled]="ableToCompleteSubtaskIndex < subtaskIndex"
  (click)="onSubtask($event)"
>
  <div class="subtask-header">
    <div
      [matTooltip]="
        (subtask | hasUpdateableMetrics) && !subtask.complete ? 'You must update metrics to complete this subtask' : ''
      "
    >
      <current-sked-checkbox
        [checked]="subtask.complete"
        [loading]="loading?.subtaskIndex === subtaskIndex && loading?.checkbox"
        [disabled]="(subtask | hasUpdateableMetrics) && !subtask.complete"
        (checkboxClick)="onCheckboxClick($event)"
      ></current-sked-checkbox>
    </div>
    <span>{{ subtask.title }}</span>
    @if (subtask | hasUpdateableMetrics) {
      <mat-icon svgIcon="tracker"></mat-icon>
    }
  </div>
</button>
