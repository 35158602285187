@if ({currentTracker: currentTracker$ | async}; as state) {
  @if (loadingNewTracker$ | async) {
    <div class="oph-loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    <main>
      @if (!metricView) {
        <h1>Create a new tracker.</h1>
        <div class="name-input-container">
          <mat-icon svgIcon="tracker"></mat-icon>
          <oph-input-orange
            [control]="trackerForm.get('name')"
            placeholder="Tracker name"
            [inputStyle]="{width: '100%', padding: '0 0 0 44px', fontSize: '16px'}"
          ></oph-input-orange>
        </div>

        <oph-button
          color="orange"
          size="small"
          [style]="{width: '157px', height: '30px', 'font-size': '14px', 'border-radius': '6px'}"
          (buttonClick)="onAddMetric()"
        >
          <mat-icon svgIcon="plus"></mat-icon>
          add metric
        </oph-button>

        <div cdkDropList (cdkDropListDropped)="drop($event)">
          @for (metric of state.currentTracker?.metrics || []; track metric._id) {
            <div
              class="metric-container"
              cdkDrag
              style="border-bottom: 1px solid var(--border)"
              (click)="onMetricClick(metric)"
            >
              <div class="drag-handle" cdkDragHandle>
                <oph-icon name="3-line-handle" size="19"></oph-icon>
              </div>
              @if (!hideMetricCheckboxes) {
                <current-sked-checkbox
                  textOrientation="right"
                  [checked]="metricsCheckedMap[metric._id]"
                  (checkboxClick)="onMetricCheckChange(metric._id, $event)"
                ></current-sked-checkbox>
              }
              <span>{{ metric.name }}</span>

              @if (!hideMetricCheckboxes) {
                <oph-checkbox
                  [checked]="metricsReadOnlyMap[metric._id]"
                  [textStyle]="{
                    color: 'var(--grey-med)',
                    fontSize: '14px',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    lineHeight: '24px',
                    whiteSpace: 'nowrap'
                  }"
                  text="Read only"
                  textOrientation="right"
                  (click)="$event.stopPropagation()"
                  (valueChange)="onMetricReadOnlyChange(metric._id, $event)"
                >
                </oph-checkbox>
              }
            </div>
          }
        </div>
      } @else {
        <h1>Edit metric</h1>
        <tracker-detail-metric [metricForm]="metricForm" [editing]="!!metricBeingEdited"></tracker-detail-metric>
      }
    </main>

    @if (!metricView) {
      <div class="button-container">
        <oph-button appearance="outline" color="gray" (buttonClick)="onTrackerCancel()">Cancel</oph-button>
        <oph-button color="green" [disabled]="!state.currentTracker?.metrics?.length" (buttonClick)="onTrackerDone()"
          >Done</oph-button
        >
      </div>
    } @else {
      <div class="button-container">
        <oph-button appearance="outline" color="gray" (buttonClick)="onMetricEditBack()">Back</oph-button>
        <div class="right">
          @if (metricBeingEdited) {
            <oph-button color="red" (buttonClick)="onMetricDelete()" [loading]="loadingMetricDelete">Delete</oph-button>
          }
          <oph-button
            color="green"
            [disabled]="!metricEditValid"
            (buttonClick)="onMetricEditDone()"
            [loading]="loadingMetricDone"
            >Done</oph-button
          >
        </div>
      </div>
    }
  }
}
