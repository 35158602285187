@if (tracker) {
  <div class="metrics-container">
    <div class="metrics-container-header">
      <span>{{ metrics.length || 0 }} METRIC{{ metrics.length === 1 ? '' : 'S' }}</span>
      <oph-button
        color="orange"
        size="small"
        [style]="{padding: '0 12px', 'font-size': '14px', 'border-radius': '10px'}"
        (buttonClick)="openMetricDialog(null)"
      >
        <mat-icon svgIcon="plus"></mat-icon>
        Add Metric
      </oph-button>
    </div>

    <div class="metrics-list-container" cdkDropList (cdkDropListDropped)="onDrop($event)">
      @if (!metrics.length) {
        <button class="add-metric-button" (click)="openMetricDialog(null)">
          <mat-icon svgIcon="plus"></mat-icon>
          Add Metric
        </button>
      }

      @for (metric of metrics; track metric; let i = $index) {
        <div class="metric" cdkDrag (click)="openMetricDialog(i)">
          <div class="metric-drag-handle" cdkDragHandle>
            <oph-icon class="drag-icon" name="3-line-handle" size="19" cdkDragHandle></oph-icon>
          </div>
          <div class="metric-main-content">
            @if ((loadingMetricIndex$ | async) === i) {
              <div class="loading-metric">
                <oph-loading></oph-loading>
              </div>
            }
            <div class="row">
              <span class="metric-name">{{ metric.name }}</span>
              <div class="row-1-right-container">
                @if (metric.updateable) {
                  <oph-icon name="check-brown-small" size="14"></oph-icon>
                  <span class="editable">Editable</span>
                }
                <list-view-menu [options]="menuOptions" (action)="onMenuAction($event, i)"></list-view-menu>
              </div>
            </div>
            <div class="bottom-row">
              <div class="label-item current-value-label-item">
                <label>CURRENT VALUE</label>
                <tracker-metric-value-update
                  [metric]="metric"
                  [control]="currentValueControlArray.controls[i]"
                  [options]="metric.options"
                  [inputStyle]="{
                    width: '178px',
                    height: '40px',
                    'font-size': '16px',
                    'font-weight': '400',
                    color: 'var(--brown-med)'
                  }"
                  [selectStyle]="{
                    width: '158px',
                    height: '40px',
                    'font-size': '16px',
                    'font-weight': '400',
                    color: 'var(--brown-med)'
                  }"
                  [showNumericUpdateButton]="true"
                  [loadingStatus]="(loadingMetricUpdateIndex$ | async) === i ? (loadingMetricStatus$ | async) : null"
                  toggleWidth="178px"
                  (click)="$event.stopPropagation()"
                  (updateValue)="onUpdateValue($event, i)"
                ></tracker-metric-value-update>
              </div>
              <div class="label-item">
                <label>DEFAULT VALUE</label>
                <span>{{
                  metric.type === trackerMetricTypes.Boolean
                    ? (metric.defaultValue | numberToBoolean)
                    : metric.defaultValue
                }}</span>
              </div>
              <div class="empty-space"></div>
              <div class="type">{{ metric.type | trackerMetricTypeDisplay }}</div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
