import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {QuillModule} from 'ngx-quill';
import {OphAutocompleteComponent} from '../design/oph-autocomplete/oph-autocomplete.component';
import {OphButtonModule} from '../design/oph-button/oph-button.module';
import {OphIconModule} from '../design/oph-icon/oph-icon.module';
import {OphInlineEditMenuModule} from '../design/oph-inline-edit-menu/oph-inline-edit-menu.module';
import {OphInputsModule} from '../design/oph-inputs/oph-inputs.module';
import {OphRadioButtonModule} from '../design/oph-radio-button/oph-radio-button.module';
import {PipesModule} from '../pipes/pipes.module';
import {TaskBackButtonComponent} from './back-button/task-back-button.component';
import {TaskBottomSheetComponent} from './bottom-sheet/task-bottom-sheet.component';
import {TaskCalendarHeaderComponent} from './calendar/header/task-calendar-header.component';
import {TaskCalendarComponent} from './calendar/task-calendar.component';
import {TaskDurationSliderComponent} from './duration-slider/task-duration-slider.component';
import {TaskIconPickerButtonComponent} from './icon-picker-button/task-icon-picker-button.component';
import {TaskIconPickerComponent} from './icon-picker/task-icon-picker.component';
import {TaskLocationSelectorComponent} from './location-selector/task-location-selector.component';
import {NewTaskButtonModule} from './new-task-button/new-task-button.module';
import {TaskNumberInputComponent} from './number-input/task-number-input.component';
import {TaskPrioritySelectorComponent} from './priority-selector/task-priority-selector.component';
import {ProvisionsSelectorComponent} from './provisions-selector/provisions-selector.component';
import {TaskRepetitionComponent} from './repetition/task-repetition.component';
import {SubtaskEditMetricSelectionComponent} from './subtasks/subtask-edit/metric-selection/subtask-edit-metric-selection.component';
import {SubtaskEditComponent} from './subtasks/subtask-edit/subtask-edit.component';
import {SubtaskListComponent} from './subtasks/subtask-list/subtask-list.component';
import {TempIconModalComponent} from './temp-icon-modal/temp-icon-modal.component';
import {SharedCurrentSkedModule} from '../current-sked/shared-current-sked.module';
import {OphXButtonComponent} from '../design/oph-x-button/oph-x-button.component';
import {OphNewButtonComponent} from '../design/oph-new-button/oph-new-button.component';

@NgModule({
  imports: [
    CommonModule,
    NewTaskButtonModule,
    OphIconModule,
    MatMenuModule,
    PipesModule,
    OphInputsModule,
    OphButtonModule,
    QuillModule.forRoot(),
    MatProgressSpinnerModule,
    DragDropModule,
    MatIconModule,
    OphInlineEditMenuModule,
    ReactiveFormsModule,
    PipesModule,
    MatSliderModule,
    MatDatepickerModule,
    OphRadioButtonModule,
    SubtaskEditMetricSelectionComponent,
    OphAutocompleteComponent,
    SharedCurrentSkedModule,
    OphXButtonComponent,
    OphNewButtonComponent,
  ],
  declarations: [
    SubtaskListComponent,
    SubtaskEditComponent,
    TaskDurationSliderComponent,
    TaskCalendarComponent,
    TaskCalendarHeaderComponent,
    TaskBackButtonComponent,
    TaskNumberInputComponent,
    TaskRepetitionComponent,
    TaskPrioritySelectorComponent,
    TaskLocationSelectorComponent,
    TaskBottomSheetComponent,
    TempIconModalComponent,
    TaskIconPickerComponent,
    TaskIconPickerButtonComponent,
    ProvisionsSelectorComponent,
  ],
  exports: [
    NewTaskButtonModule,
    SubtaskListComponent,
    SubtaskEditComponent,
    TaskDurationSliderComponent,
    TaskCalendarComponent,
    TaskBackButtonComponent,
    TaskNumberInputComponent,
    TaskRepetitionComponent,
    TaskPrioritySelectorComponent,
    TaskLocationSelectorComponent,
    TaskBottomSheetComponent,
    TaskIconPickerButtonComponent,
    TaskIconPickerComponent,
    ProvisionsSelectorComponent,
    TaskCalendarComponent,
  ],
})
export class SharedTasksModule {}
