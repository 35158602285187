import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'oph-new-button',
  standalone: true,
  imports: [MatIconModule, CommonModule, MatProgressSpinnerModule],
  templateUrl: './oph-new-button.component.html',
  styleUrl: './oph-new-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphNewButtonComponent {
  @Input() buttonStyle: Object;
  @Input() showMinus: boolean;
  @Input() loading: boolean;

  @Output() buttonClick = new EventEmitter();
}
