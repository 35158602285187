import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {TrackersApiService} from '../../api/trackers-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  AddMetricToTrackerAction,
  AddMetricToTrackerSuccessAction,
  CreateNewTrackerAction,
  CreateNewTrackerMetricAction,
  CreateNewTrackerMetricSuccessAction,
  CreateNewTrackerSuccessAction,
  DeleteTrackerAction,
  DeleteTrackerMetricAction,
  DeleteTrackerMetricSuccessAction,
  DuplicateTrackerAction,
  DuplicateTrackerMetricAction,
  DuplicateTrackerMetricSuccessAction,
  DuplicateTrackerSuccessAction,
  GetAllTrackersAction,
  GetAllTrackersSuccessAction,
  GetSingleTrackerAction,
  GetSingleTrackerSuccessAction,
  TrackersActionType,
  UpdateTrackerAction,
  UpdateTrackerMetricAction,
  UpdateTrackerMetricValueAction,
  UpdateTrackerMetricValueSuccessAction,
  UpdateTrackerSuccessAction,
} from './trackers.action';

@Injectable()
export class TrackersEffects {
  public getAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTrackersAction>(TrackersActionType.GET_ALL),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.getAll(params).pipe(
          mergeMap(trackerData => [
            new GetAllTrackersSuccessAction({trackerData}),
            ...createCallbackActions(onSuccess, trackerData),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleTrackerAction>(TrackersActionType.GET_SINGLE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.getSingle(id).pipe(
          mergeMap(trackerDetailData => [
            new GetSingleTrackerSuccessAction({trackerDetailData}),
            ...createCallbackActions(onSuccess, trackerDetailData),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createNew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewTrackerAction>(TrackersActionType.CREATE_NEW),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.trackersApiService.createNew().pipe(
          mergeMap(tracker => [
            new CreateNewTrackerSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTrackerAction>(TrackersActionType.UPDATE),
      mergeMap(action => {
        const {tracker, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.update(tracker).pipe(
          mergeMap(tracker => [
            new UpdateTrackerSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public duplicate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateTrackerAction>(TrackersActionType.DUPLICATE),
      mergeMap(action => {
        const {trackerId, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.duplicate(trackerId).pipe(
          mergeMap(tracker => [
            new DuplicateTrackerSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTrackerAction>(TrackersActionType.DELETE),
      mergeMap(action => {
        const {trackerId, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.delete(trackerId).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createNewMetric$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewTrackerMetricAction>(TrackersActionType.CREATE_NEW_METRIC),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.trackersApiService.createNewMetric().pipe(
          mergeMap(tracker => [
            new CreateNewTrackerMetricSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public addMetricToTracker$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AddMetricToTrackerAction>(TrackersActionType.ADD_METRIC_TO_TRACKER),
      mergeMap(action => {
        const {trackerId, metric, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.addMetricToTracker(trackerId, metric).pipe(
          mergeMap(tracker => [
            new GetSingleTrackerAction({id: trackerId}),
            new AddMetricToTrackerSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateMetric$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTrackerMetricAction>(TrackersActionType.UPDATE_METRIC),
      mergeMap(action => {
        const {trackerId, metric, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.updateMetric(trackerId, metric).pipe(
          mergeMap(tracker => [
            new GetSingleTrackerAction({id: trackerId}),
            new AddMetricToTrackerSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateMetricValue$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTrackerMetricValueAction>(TrackersActionType.UPDATE_METRIC_VALUE),
      mergeMap(action => {
        const {trackerId, metricId, value, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.updateMetricValue(trackerId, metricId, value).pipe(
          mergeMap(tracker => [
            new UpdateTrackerMetricValueSuccessAction({tracker}),
            ...createCallbackActions(onSuccess, tracker),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public duplicateMetric$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateTrackerMetricAction>(TrackersActionType.DUPLICATE_METRIC),
      mergeMap(action => {
        const {trackerId, metricId, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.duplicateMetric(trackerId, metricId).pipe(
          mergeMap(() => [
            new GetSingleTrackerAction({id: trackerId}),
            new DuplicateTrackerMetricSuccessAction(),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteMetric$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTrackerMetricAction>(TrackersActionType.DELETE_METRIC),
      mergeMap(action => {
        const {trackerId, metricId, onSuccess, onFailure} = action.payload;

        return this.trackersApiService.deleteMetric(trackerId, metricId).pipe(
          mergeMap(() => [
            new GetSingleTrackerAction({id: trackerId}),
            new DeleteTrackerMetricSuccessAction(),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private trackersApiService: TrackersApiService
  ) {}
}
