import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, NonNullableFormBuilder} from '@angular/forms';
import {Project} from 'src/app/core/model/project';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';

@Injectable({
  providedIn: 'root',
})
export class TrackersService {
  constructor(private formBuilder: NonNullableFormBuilder) {}

  getTrackerForm(tracker?: Tracker): FormGroup {
    return this.formBuilder.group({
      name: [tracker?.name || ''],
      projectIds: this.formBuilder.array(
        tracker?.projectIds ? tracker.projectIds.map(projectId => this.formBuilder.control(projectId)) : []
      ),
    });
  }

  getProjectsFormGroup(project?: Partial<Project>): AbstractControl {
    return this.formBuilder.group({
      _id: [project?._id || ''],
      name: [project?.label || ''],
      color: [project?.color || ''],
    });
  }

  getMetricsFormGroup(metric?: TrackerMetric): FormGroup {
    return this.formBuilder.group({
      name: [metric?.name || ''],
      type: [metric?.type || 'numeric'],
      uom: [metric?.uom || ''],
      min: [metric?.min || ''],
      max: [metric?.max || ''],
      options: this.formBuilder.array(
        metric?.options
          ? metric.options.map(option => this.getOptionsFormGroup(metric?.defaultValue, option))
          : [
              this.formBuilder.group({
                value: [''],
                default: [false],
              }),
            ]
      ),
      updateable: [metric?.updateable === undefined ? true : metric?.updateable],
      defaultValue: [metric?.defaultValue || ''],
      value: [metric?.value || ''],
    });
  }

  getOptionsFormGroup(defaultValue: string, option?: string) {
    return this.formBuilder.group({
      value: [option || ''],
      default: [option ? option === defaultValue : false],
    });
  }

  isMetricFormValid(metric: TrackerMetric): boolean {
    if (metric.type === 'select') {
      // eslint-disable-next-line
      return !!(metric.name && metric.options?.length > 0 && metric.options.some((option: any) => !!option.value));
    }
    return !!metric.name;
  }

  formatMetricDto(metricForm: FormGroup): TrackerMetric {
    const formMetric = metricForm.value;
    let defaultValue = formMetric.defaultValue;
    let options: string[];
    if (formMetric.type === TrackerMetricTypes.Select) {
      defaultValue = formMetric.options.find(option => option.default)?.value || '';
      options = formMetric.options.filter(option => option.value).map(option => option.value);
    } else {
      options = [];
    }
    if (formMetric.type === TrackerMetricTypes.Numeric && !formMetric.value) {
      formMetric.value = '0';
    }

    return {
      ...formMetric,
      options,
      defaultValue,
      value: formMetric.value,
    };
  }
}
