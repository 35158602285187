import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {
  Project,
  ProjectView,
  ProjectViewRoutineDetail,
  ProjectViewRoutineDetailParams,
  ProjectViewTokenFilterParams,
} from '../../model/project';
import {ProjectTask, TaskTemplate} from '../../model/task';

export enum ProjectsActionType {
  GET = '[Projects] Get',
  GET_SUCCESS = '[Projects] Get :: Success',

  CREATE = '[Projects] Create',
  CREATE_SUCCESS = '[Projects] Create :: Success',

  PATCH = '[Projects] Patch',
  PATCH_SUCCESS = '[Projects] Update :: Success',

  PUT = '[Projects] Update',

  UPDATE_SUCCESS = '[Projects] Update :: Success',

  DUPLICATE = '[Projects] Duplicate',
  DUPLICATE_SUCCESS = '[Projects] Duplicate :: Success',

  DELETE = '[Projects] Delete',
  DELETE_SUCCESS = '[Projects] Delete :: Success',

  ADD_PROJECT_TASK = '[Projects] Add Project Task',
  UPDATE_PROJECT_TASK = '[Projects] Update Project Task',
  MOVE_PROJECT_TASK = '[Projects] Move Project Task',
  DELETE_PROJECT_TASK = '[Projects] Delete Project Task',

  GET_PROJECT_VIEW = '[Projects] Get Project View',
  GET_PROJECT_VIEW_SUCCESS = '[Projects] Get Project View :: Success',

  UPDATE_PROJECT_VIEW_TASK_ID = '[Projects] Update Project View Task Id :: Success',

  GET_ROUTINE_DETAIL = '[Projects] Get Routine',
  GET_ROUTINE_DETAIL_SUCCESS = '[Projects] Get Routine :: Success',

  UPDATE_ROUTINE_DETAIL_PARAMS = '[Projects]  Update Routine Detail Params',
  UPDATE_PROJECT_VIEW_TOKEN_PARAMS = '[Projects] Update Project View Token Params',

  CREATE_STEP = '[Projects] Create Step',
  UPDATE_STEP = '[Projects] Update Step',
  DELETE_STEP = '[Projects] Delete Step',

  GET_TASK_TEMPLATES = '[Projects] Get Task Templates',
  GET_TASK_TEMPLATES_SUCCESS = '[Projects] Get Task Templates :: Success',

  CREATE_TASK_TEMPLATE = '[Projects] Create Task Template',
  UPDATE_TASK_TEMPLATE = '[Projects] Update Task Template',
  DELETE_TASK_TEMPLATE = '[Projects] Delete Task Template',

  UPDATE_TWD_SUBTASK_VIEW = '[Projects] Update Subtask Metric View',

  UPDATE_PAGE_VIEW = '[Projects] Update Page View',

  CLEAR = '[Projects] Clear',
}

export class GetProjectsAction implements Action {
  public readonly type = ProjectsActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (projects: string[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetProjectsSuccessAction implements Action {
  public readonly type = ProjectsActionType.GET_SUCCESS;

  public constructor(public payload: {projects: Project[]}) {}
}

export class CreateProjectAction implements Action {
  public readonly type = ProjectsActionType.CREATE;

  public constructor(
    public payload: {
      project: Project;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.CREATE_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class PutProjectAction implements Action {
  public readonly type = ProjectsActionType.PUT;

  public constructor(
    public payload: {
      project: Project;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class PatchProjectAction implements Action {
  public readonly type = ProjectsActionType.PATCH;

  public constructor(
    public payload: {
      project: Project;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class DuplicateProjectAction implements Action {
  public readonly type = ProjectsActionType.DUPLICATE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (project: Project) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DuplicateProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.DUPLICATE_SUCCESS;

  public constructor(public payload: {project: Project}) {}
}

export class DeleteProjectAction implements Action {
  public readonly type = ProjectsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteProjectSuccessAction implements Action {
  public readonly type = ProjectsActionType.DELETE_SUCCESS;
}

export class AddProjectTaskAction implements Action {
  public readonly type = ProjectsActionType.ADD_PROJECT_TASK;

  public constructor(
    public payload: {
      projectId: string;
      stepId: string;
      taskIndex: number; // index to insert new task
      task?: ProjectTask;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateProjectTaskAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_PROJECT_TASK;

  public constructor(
    public payload: {
      projectId: string;
      stepId: string;
      task: ProjectTask;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

//This moves task fro m one step to another
export class MoveProjectTaskAction implements Action {
  public readonly type = ProjectsActionType.MOVE_PROJECT_TASK;

  public constructor(
    public payload: {
      projectId: string;
      stepId: string;
      taskIndex: number; // index to insert new task
      task?: ProjectTask;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteProjectTaskAction implements Action {
  public readonly type = ProjectsActionType.DELETE_PROJECT_TASK;

  public constructor(
    public payload: {
      projectId: string;
      taskInstanceId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetProjectViewAction implements Action {
  public readonly type = ProjectsActionType.GET_PROJECT_VIEW;

  public constructor(
    public payload: {
      projectId: string;
      params: Record<string, string>;
      onSuccess?: (projectView: ProjectView) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetProjectViewSuccessAction implements Action {
  public readonly type = ProjectsActionType.GET_PROJECT_VIEW_SUCCESS;

  public constructor(public payload: {projectView: ProjectView}) {}
}

export class UpdateProjectViewTaskId implements Action {
  public readonly type = ProjectsActionType.UPDATE_PROJECT_VIEW_TASK_ID;

  public constructor(public payload: {id: string}) {}
}

export class GetProjectViewRoutineDetailAction implements Action {
  public readonly type = ProjectsActionType.GET_ROUTINE_DETAIL;

  public constructor(
    public payload: {
      taskInstanceId: string;
      params: ProjectViewRoutineDetailParams;
      onSuccess?: (projectView: ProjectView) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetProjectViewRoutineDetailSuccessAction implements Action {
  public readonly type = ProjectsActionType.GET_ROUTINE_DETAIL_SUCCESS;

  public constructor(public payload: {routineDetail: ProjectViewRoutineDetail; taskInstanceId: string}) {}
}

export class UpdateRoutineDetailParamsAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_ROUTINE_DETAIL_PARAMS;

  public constructor(
    public payload: {
      params: ProjectViewRoutineDetailParams;
    }
  ) {}
}

export class UpdateProjectViewTokenParamsAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_PROJECT_VIEW_TOKEN_PARAMS;

  public constructor(
    public payload: {
      params: ProjectViewTokenFilterParams;
    }
  ) {}
}

export class CreateProjectStepAction implements Action {
  public readonly type = ProjectsActionType.CREATE_STEP;

  public constructor(
    public payload: {
      // this will change
      project: Project;
      onSuccess?: (taskTemplates: TaskTemplate[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateProjectStepAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_STEP;

  public constructor(
    public payload: {
      // this will change
      project: Project;
      onSuccess?: (taskTemplates: TaskTemplate[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteProjectStepAction implements Action {
  public readonly type = ProjectsActionType.DELETE_STEP;

  public constructor(
    public payload: {
      // this will change
      project: Project;
      onSuccess?: (taskTemplates: TaskTemplate[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetTaskTemplatesAction implements Action {
  public readonly type = ProjectsActionType.GET_TASK_TEMPLATES;

  public constructor(
    public payload: {
      search?: string;
      onSuccess?: (taskTemplates: TaskTemplate[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetTaskTemplatesSuccessAction implements Action {
  public readonly type = ProjectsActionType.GET_TASK_TEMPLATES_SUCCESS;

  public constructor(public payload: {taskTemplates: TaskTemplate[]}) {}
}

export class CreateTaskTemplateAction implements Action {
  public readonly type = ProjectsActionType.CREATE_TASK_TEMPLATE;
  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: (task: TaskTemplate) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateTaskTemplateAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_TASK_TEMPLATE;
  public constructor(
    public payload: {
      task: TaskTemplate;
      onSuccess?: (task: TaskTemplate) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class DeleteTaskTemplateAction implements Action {
  public readonly type = ProjectsActionType.DELETE_TASK_TEMPLATE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (err: Error) => void;
    }
  ) {}
}

export class UpdateTwdSubtaskViewAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_TWD_SUBTASK_VIEW;

  public constructor(public payload: {view: string}) {}
}

export class UpdatePageViewAction implements Action {
  public readonly type = ProjectsActionType.UPDATE_PAGE_VIEW;

  public constructor(public payload: {pageView: string}) {}
}

export class ClearProjectsAction implements Action {
  public readonly type = ProjectsActionType.CLEAR;
}

export type ProjectsAction =
  | GetProjectsAction
  | GetProjectsSuccessAction
  | CreateProjectAction
  | CreateProjectSuccessAction
  | PutProjectAction
  | PatchProjectAction
  | UpdateProjectSuccessAction
  | DuplicateProjectAction
  | DuplicateProjectSuccessAction
  | DeleteProjectAction
  | DeleteProjectSuccessAction
  | AddProjectTaskAction
  | UpdateProjectTaskAction
  | MoveProjectTaskAction
  | DeleteProjectTaskAction
  | GetProjectViewAction
  | GetProjectViewSuccessAction
  | UpdateProjectViewTaskId
  | GetProjectViewRoutineDetailAction
  | GetProjectViewRoutineDetailSuccessAction
  | UpdateRoutineDetailParamsAction
  | UpdateProjectViewTokenParamsAction
  | CreateProjectStepAction
  | UpdateProjectStepAction
  | DeleteProjectStepAction
  | GetTaskTemplatesAction
  | GetTaskTemplatesSuccessAction
  | CreateTaskTemplateAction
  | DeleteTaskTemplateAction
  | UpdateTwdSubtaskViewAction
  | UpdatePageViewAction
  | ClearProjectsAction;
