@if (
  {
    selectedTracker: selectedTracker$ | async,
    filteredMetrics: filteredMetrics$ | async
  };
  as state
) {
  <oph-autocomplete
    placeholder="Name of the tracker"
    [options]="autocompleteOptions$ | async"
    [style]="{height: '52px'}"
    [inputValue]="state.selectedTracker?.name"
    (optionChange)="onTracker($event)"
  ></oph-autocomplete>

  <div class="metrics-container oph-scroll-bar">
    @if (subtaskFormControl?.value?.trackerId) {
      @if (state.filteredMetrics?.length) {
        @for (metric of state.filteredMetrics; track metric._id) {
          <div class="metric-item">
            <current-sked-checkbox
              textOrientation="right"
              [checked]="metricsCheckedMap[metric._id]"
              (checkboxClick)="onMetricCheckChange(metric._id, $event)"
            ></current-sked-checkbox>
            <span class="metric-name">{{ metric.name }}</span>
            <oph-checkbox
              class="read-only-checkbox"
              [checked]="metricsReadOnlyMap[metric._id]"
              [textStyle]="{
                color: 'var(--grey-med)',
                fontSize: '14px',
                fontStyle: 'italic',
                fontWeight: '400',
                lineHeight: '24px',
                whiteSpace: 'nowrap'
              }"
              text="Read only"
              textOrientation="right"
              (click)="$event.stopPropagation()"
              (valueChange)="onMetricReadOnlyChange(metric._id, $event)"
            >
            </oph-checkbox>
          </div>
        }
      } @else {
        <div class="empty">
          <span class="metric-name">This tracker has no updateable metrics</span>
        </div>
      }
    } @else {
      <div class="empty">
        <span class="metric-name">Select a tracker</span>
      </div>
    }
  </div>
}
