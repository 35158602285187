<div class="container" [style.margin-bottom.px]="marginBottom || 0">
  @if (view === 'metric') {
    <div class="space-between-buttons">
      <oph-button appearance="outline" color="gray" (buttonClick)="back.emit()">Back</oph-button>
      <oph-button color="green" (buttonClick)="next.emit()">Next</oph-button>
    </div>
  }
  @if (view === 'subtask') {
    <oph-button appearance="outline" color="gray" (buttonClick)="cancel.emit()">Cancel</oph-button>
    <oph-button color="green" [disabled]="disabled" (buttonClick)="ok.emit()">OK</oph-button>
  }
</div>
